// src/listingApi.js

import { API_URL } from '../util/config';

/**
 * Helper function to create FormData for a listing
 */
const buildFormData = (data, selectedFiles, imagesDeleted) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  selectedFiles.forEach((image) => {
    formData.append('images', image);
  });
  if (imagesDeleted && imagesDeleted.length > 0) {
    formData.append('images_deleted', imagesDeleted.join(',')); // Pass deleted image IDs as comma-separated string
  }
  return formData;
};

/**
 * Common function to send listing data to a specified endpoint
 */
const sendListingRequest = async (formData, endpoint, method = 'POST') => {
  try {
    const response = await fetch(`${API_URL}/${endpoint}`, {
      method,
      body: formData,
      credentials: 'include',
    });
    if (!response.ok) {
      console.error('Error response:', response);
      return response;
    }
    return response;
  } catch (error) {
    console.error('Error with listing request:', error);
    return false;
  }
};

// Listing creation functions for each listing type (unchanged from previous example)

export const createAdoptionListing = async (listingData, selectedFiles) => {
  const formData = buildFormData(listingData, selectedFiles);
  formData.set('adoptOrFoster', 0);
  if (formData.get('locationID')) {
    formData.set('pickupLocationID', formData.get('locationID'));
    formData.delete('locationID');
  }
  return sendListingRequest(formData, 'adoptFoster');
};

// ... (createFosterListing, createFoundListing, createLostListing, createRehomeListing as before)

// Listing update function

export const updateListing = async (listingData, selectedFiles, imagesDeleted, listingID, listingType) => {
  const formData = buildFormData(listingData, selectedFiles, imagesDeleted);

  // Rename location fields based on listing type
  switch (listingType) {
    case 'adoptFoster':
      formData.set('adoptOrFoster', listingData.adoptOrFoster);
      if (formData.get('locationID')) {
        formData.set('pickupLocationID', formData.get('locationID'));
        formData.delete('locationID');
      }
      break;
    case 'found':
      if (formData.get('locationID')) {
        formData.set('foundLocationID', formData.get('locationID'));
        formData.delete('locationID');
      }
      break;
    case 'lost':
      if (formData.get('locationID')) {
        formData.set('lastSeenLocationID', formData.get('locationID'));
        formData.delete('locationID');
      }
      break;
    case 'rehome':
      if (formData.get('locationID')) {
        formData.set('currentLocationID', formData.get('locationID'));
        formData.delete('locationID');
      }
      break;
    default:
      throw new Error(`Unknown listing type: ${listingType}`);
  }

  // Send a PATCH request to update the listing
  return sendListingRequest(formData, `listing/${listingID}`, 'PATCH');
};

export const getListingByID = async (listingID) => {
  try {
    const response = await fetch(`${API_URL}/listing/${listingID}`, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) {
      console.error('Error response:', response);
      return response;
    }
    return response.json();
  } catch (error) {
    console.error('Error getting listing:', error);
    return false;
  }
}

export const updateListingByID = async (updateData) => {
  try {
    const response = await fetch(`${API_URL}/listing/${updateData.listingID}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
      credentials: 'include',
    });
    if (!response.ok) {
      console.error('Error response:', response);
      return response;
    }
    return response;
  } catch (error) {
    console.error('Error updating listing:', error);
    return false;
  }
}

export const deleteListingByID = async (listingID) => {
  try {
    const response = await fetch(`${API_URL}/listing/${listingID}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (!response.ok) {
      console.error('Error response:', response);
      return response;
    }
    return response;
  } catch (error) {
    console.error('Error deleting listing:', error);
    return false;
  }
}
