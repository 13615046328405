import pastListings from "./PastListings.module.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAccountDetails, getUserAdoptFosterListings, getUserRehomeListings, getUserLostListings, getUserFoundListings } from "../../api/userApi";
import { useUser } from "../../context/UserContext";
import {refreshToken} from "../../api/authApi";
import { Button } from "@mui/material";

export default function PastListings() {
    const [myPastAdoptListings, setMyPastAdoptListings] = useState("");
    const [myPastFosterListings, setMyPastFosterListings] = useState("");
    const [myPastRehomeListings, setMyPastRehomeListings] = useState("");
    const [myPastLostListings, setMyPastLostListings] = useState("");
    const [myPastFoundListings, setMyPastFoundListings] = useState("");
    const { user, setUser } = useUser();
    const navigate = useNavigate();

    // Type: 0 means adopt listing, status: 0 means past/inactive listing
    const [adoptUserData, setAdoptUserData] = useState({
        type: 0,
        status: 0,
    });

    // Type: 1 means foster listing, status: 0 means past/inactive listing
    const [fosterUserData, setFosterUserData] = useState({
        type: 1,
        status: 0,
    });

    // Status: 0 means past/inactive listings (this is for rehome/lost/found listings)
    const [userData, setUserData] = useState({
        status: 0,
    })

    const Pets = (props) => (
            <div>
                <div className={pastListings.overallPetPost}>
                    <Link className={pastListings.petPost} to={navigateListing(props)}>
                        {props.pet.picture[0] ? (
                            <img
                                src={props.pet.picture[0]}
                                className={pastListings.petPicture}
                                alt="Pet Image"
                            />
                        ) : (
                            <img
                                src= "/Image_not_available.png"
                                alt="Image Not Available"
                                className={pastListings.petPicture}
                            />
                        )}
                        <div className={pastListings.containerPetPostText}>
                            {props.pet.name}
                        </div>
                    </Link>

                    <div className={pastListings.buttonSubmit}>
                        <Button style={{ backgroundColor: "#aaf0d1", fontFamily: "Koulen", color: "black", fontSize: 20, borderRadius: 10}} variant="contained" onClick={() => {handleEditButtonClick(props)}}>Edit Pet Info</Button>
                    </div>
                </div>
            </div>
    );

    function navigateListing(props) {
        if (props.pet.listingType === "AdoptFosterListing") {
            /* 
             * Navigate to adopt listing if adoptOrFoster is 0. 
             * Navigate to foster listing if adoptOrFoster is 1. 
             */
            if (props.pet.adoptOrFoster === 0) {
                return `/viewadoptlisting/${props.pet.listingID}`;
            }
            else {
                return `/view-foster-listing/${props.pet.listingID}`;
            }
        }
        else if (props.pet.listingType === "RehomeListing") {
            return `/viewrehomelisting/${props.pet.listingID}`;
        }
        else if (props.pet.listingType === "LostListing") {
            return `/view-lost-found-post/${props.pet.listingID}`;
        }
        else if (props.pet.listingType === "FoundListing") {
            return `/view-lost-found-post/${props.pet.listingID}`;
        }
    }

    const handleEditButtonClick = (props) => {
        navigate(`../../update-listing/${props.pet.listingID}`);
    }

    useEffect(() => {
        async function getMyPastAdoptListings() {   
            if (user != null){                
                const data = await getUserAdoptFosterListings(user.userID, adoptUserData);
                setMyPastAdoptListings(data);
            }
        }
        getMyPastAdoptListings();
        return;
    }, [adoptUserData, user]);

    useEffect(() => {
        async function getMyPastFosterListings() {
            if (user != null){                
                const data = await getUserAdoptFosterListings(user.userID, fosterUserData);
                setMyPastFosterListings(data);
            }
        }
        getMyPastFosterListings();
        return;
    }, [fosterUserData, user]);

    useEffect(() => {
        async function getMyPastRehomeListings() {
            if (user != null){                
                const data = await getUserRehomeListings(user.userID, userData);
                setMyPastRehomeListings(data);
            }
        }
        getMyPastRehomeListings();
        return;
    }, [userData, user]);

    useEffect(() => {
        async function getMyPastLostListings() {
            if (user != null){                
                const data = await getUserLostListings(user.userID, userData);
                setMyPastLostListings(data);
            }
        }
        getMyPastLostListings();
        return;
    }, [userData, user]);

    useEffect(() => {
        async function getMyPastFoundListings() {
            if (user != null){                
                const data = await getUserFoundListings(user.userID, userData);
                setMyPastFoundListings(data);
            }
        }
        getMyPastFoundListings();
        return;
    }, [userData, user]);


    function getPastAdoptListings() {
        return myPastAdoptListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getPastFosterListings() {
        return myPastFosterListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getPastRehomeListings() {
        return myPastRehomeListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getPastLostListings() {
        return myPastLostListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getPastFoundListings() {
        return myPastFoundListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function pastAdoptListingsOrNot() {
        if (myPastAdoptListings.length === 0) {
            return (
                <div className={pastListings.noListingsText}>
                    No Past Adopt Listings
                </div>
            );
        } else {
            return (
                <ul className={pastListings.listOfPets}>
                    {getPastAdoptListings()}
                </ul>
            );
        }
    }

    function pastFosterListingsOrNot() {
        if (myPastFosterListings.length === 0) {
            return (
                <div className={pastListings.noListingsText}>
                    No Past Foster Listings
                </div>
            );
        } else {
            return (
                <ul className={pastListings.listOfPets}>
                    {getPastFosterListings()}
                </ul>
            );
        }
    }

    function pastRehomeListingsOrNot() {
        if (myPastRehomeListings.length === 0) {
            return (
                <div className={pastListings.noListingsText}>
                    No Past Rehome Listings
                </div>
            );
        } else {
            return (
                <ul className={pastListings.listOfPets}>
                    {getPastRehomeListings()}
                </ul>
            );
        }
    }

    function pastLostListingsOrNot() {
        if (myPastLostListings.length === 0) {
            return (
                <div className={pastListings.noListingsText}>
                    No Past Posts for Lost Pets
                </div>
            );
        } else {
            return (
                <ul className={pastListings.listOfPets}>
                    {getPastLostListings()}
                </ul>
            );
        }
    }

    function pastFoundListingsOrNot() {
        if (myPastFoundListings.length === 0) {
            return (
                <div className={pastListings.noListingsText}>
                    No Past Posts for Found Pets
                </div>
            );
        } else {
            return (
                <ul className={pastListings.listOfPets}>
                    {getPastFoundListings()}
                </ul>
            );
        }
    }

    return (
        <div className={pastListings.page}> 
            <div className={pastListings.listingsContainer}>
                <div className={pastListings.welcomebacktext}>
                    My Past Listings:
                </div>

                <div className={pastListings.petListingsContainer}>
                    <div className={pastListings.noListingsText}>
                        <b>Adopt Listings:</b>
                    </div>
                    {pastAdoptListingsOrNot()}

                </div>

                <div className={pastListings.petListingsContainer}>
                    <div className={pastListings.noListingsText}>
                        <b>Foster Listings:</b>
                    </div>
                    {pastFosterListingsOrNot()}
                </div>

                <div className={pastListings.petListingsContainer}>
                    <div className={pastListings.noListingsText}>
                        <b>Rehome Listings:</b>
                    </div>
                    {pastRehomeListingsOrNot()}
                </div>

                <div className={pastListings.petListingsContainer}>
                    <div className={pastListings.noListingsText}>
                        <b>Lost Pet Posts</b>
                    </div>
                    {pastLostListingsOrNot()}
                </div>

                <div className={pastListings.petListingsContainer}>
                    <div className={pastListings.noListingsText}>
                        <b>Found Pet Posts:</b>
                    </div>
                    {pastFoundListingsOrNot()}
                </div>
            </div>
        </div>
    );
}