// src/bugApi.js

import { API_URL } from '../util/config';

export const createBug = async (userID, description) => {
    try {
        const response = await fetch(`${API_URL}/bug`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    userID: userID,
                    description: description
                }),
            credentials: 'include',
        });

        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllBugs = async () => {
    try {
        const response = await fetch(`${API_URL}/bug/`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

export const getBugsByUserID = async (userID) => {
    try {
        const response = await fetch(`${API_URL}/bug/userID/${userID}`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

export const getBugsByBugID = async (bugID) => {
    try {
        const response = await fetch(`${API_URL}/bug/bugID/${bugID}`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

export const deleteBugsByUserID = async (userID) => {
    try {
        const response = await fetch(`${API_URL}/bug/userID/${userID}`, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBugByBugID = async (bugID) => {
    try {
        const response = await fetch(`${API_URL}/bug/bugID/${bugID}`, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response;
    } catch (error) {
        throw error;
    }
}