import React from "react";
import { Button } from "@mui/material";
import css from "./ServerDown.module.css"; // Assuming you will style it similarly

export default function ServerDown() {
    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.container}>
                <div className={css.containerItem}>
                    <p>Error: server is down</p>
                </div>
            </div>
        </div>
    );
}
