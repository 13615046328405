import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Message = ({ content, sender, timestamp, isSender, avatar, onDelete, attachments, isGrouped }) => {
  const [hover, setHover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const formattedTimestamp = new Date(timestamp).toLocaleString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleDelete = () => {
    onDelete();
    handleCloseDialog();
  };

  return (
    <Box
      display="flex"
      justifyContent={isSender ? 'flex-end' : 'flex-start'}
      alignItems="flex-start"
      mb={isGrouped ? 0.5 : 2}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{ textAlign: 'left' }}
    >
      {!isSender && (
        <Box sx={{ width: 40, mr: 2 }}>
          {!isGrouped && <Avatar src={avatar} sx={{ width: 40, height: 40 }} />}
        </Box>
      )}

      <Box sx={{ position: 'relative', maxWidth: '80%' }}>
        {!isGrouped && (
          <Box display="flex" alignItems="center" justifyContent={isSender ? 'flex-end' : 'flex-start'}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: isSender ? 'primary.main' : 'text.primary' }}>
              {sender}
            </Typography>
            <Typography variant="caption" color="textSecondary" sx={{ ml: isSender ? 0.5 : 1, mr: isSender ? 1 : 0 }}>
              {formattedTimestamp}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            bgcolor: isSender ? 'primary.light' : 'grey.200',
            borderRadius: 1,
            p: 1.5,
            mt: 0.5,
            ml: isSender ? 'auto' : 0,
            position: 'relative',
            maxWidth: '60%',
            width: 'fit-content',
            minWidth: 'fit-content',
          }}
        >
          <Typography
            variant="body1"
            color={'text.primary'}
            sx={{ display: 'inline-block' }}
          >
            {content}
          </Typography>

          {attachments && attachments.length > 0 && (
            <Box mt={1} sx={{ maxWidth: 250, mx: 'auto' }}>
              <Carousel
                showThumbs={false}
                showIndicators={attachments.length > 1}
                showStatus={false}
                infiniteLoop
                useKeyboardArrows
                dynamicHeight
                swipeable
              >
                {attachments.map((url, index) => (
                  <div key={index}>
                    <img
                      src={url}
                      alt={`attachment-${index}`}
                      style={{
                        maxWidth: '100%',
                        maxHeight: 200,
                        objectFit: 'cover',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </Box>
          )}

          {isSender && hover && (
            <Box
              sx={{
                position: 'absolute',
                top: -12,
                right: -12,
                backgroundColor: 'grey.300',
                borderRadius: '4px',
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 1,
              }}
            >
              <IconButton
                size="small"
                onClick={handleOpenDialog}
                sx={{
                  color: 'text.secondary',
                  padding: 0,
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Delete Message</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this message?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Message;