import React, { useState, useEffect } from "react";
import { TextField, Button, Alert } from "@mui/material";
import css from "./ReportBugs.module.css";
import Snackbar from "@mui/material/Snackbar";
import { createBug } from "../../api/bugApi";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';

export default function ReportBugs() {
    const [bug, setBug] = useState("");
    const [dataUsed, setDataUsed] = useState(false);
    const [bugUsed, setBugUsed] = useState(true);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    const { user, setUser } = useUser();
    const navigate = useNavigate();

    const handleFormClick  = async () => {
        setDataUsed(true);
        if (bug === "") {
            setDataUsed(false);
            setBugUsed(false);
            handleClickAlert();
        }
        else{
            try{
                const bugData = await createBug(user.userID, bug);

                if (!bugData.ok) {
                    setFailure(!bugData.ok);
                    setTimeout(() => {
                        navigate("/home");
                      }, 5000); 
                }
                setSuccess(bugData.ok);
                if (bugData.ok){
                    setTimeout(() => {
                        navigate("/home");
                      }, 5000); 
                }
                else{
                    
                }
            
            } catch (err) {
                console.error(err);
            }

            
        }
    }

    const handleBugTextField = (event) => {
        setBug(event.target.value);
    };

    const handleClickAlert = () => {
        setState({ ...state, open: true });
    }

    const handleCloseAlert = () => {
        setState({ ...state, open: false });
    };

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.containerwithitems}>
                <Snackbar
                        autoHideDuration={5000}
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={handleCloseAlert}
                        message="Please enter information about the bug in the field below."
                        key={vertical + horizontal}
                    />
                <div className={css.title}>
                    Report Bugs
                </div>
                <div className={css.description}>
                    If you encountered any bugs or have any issues on the website, please describe the experience in the form below. 
                </div>
                <div>
                    <TextField 
                        placeholder="Describe what happened, where it happened ..." 
                        size="medium" 
                        fullWidth 
                        onChange={handleBugTextField} 
                        disabled={dataUsed} 
                        multiline={true} 
                        rows={10} 
                        error={bugUsed === false ? true : false} 
                        required />
                </div>

                <div className={css.submitButton}>
                    <Button 
                        style={{ backgroundColor: "#555B6E" }} 
                        variant="contained" 
                        onClick={handleFormClick} 
                        disabled={dataUsed}
                        size="large">
                            SUBMIT
                    </Button>
                </div>
                {success && (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Bug report has been successfully created! You will be redirected to the home page in 5 seconds.
                        </Alert>
                    )}

                {failure && (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Bug report was not created. Please redo the form. You will be redirected to the home page in 5 seconds.
                        </Alert>
                    )}  
            </div>
        </div>
    );
} 