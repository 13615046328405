// src/components/NewChatDialog.js

import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Button, List, ListItem, ListItemText
} from '@mui/material';

const NewChatDialog = ({ open, onClose, onCreateChat }) => {
  const [username, setUsername] = useState('');

  const handleAddChat = () => {
    if (username.trim()) {
      onCreateChat(username);
      setUsername('');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Start a New Chat</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          label="Enter Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddChat} color="primary" variant="contained">
          Start Chat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewChatDialog;
