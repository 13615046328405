// src/chatApi.js

import { API_URL } from '../util/config';

export const getChatsForUser = async () => {
    try {
        const response = await fetch(`${API_URL}/chat`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);;
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching chats:', error);
        return null;
    }
};

export const createChat = async (participants) => {
    try {
        const response = await fetch(`${API_URL}/chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ participants }),
        });

        if (!response.ok) {
            console.error(response);
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating chat:', error);
        return null;
    }
};

export const createChatByID = async (userIDs) => {
    try {
        const response = await fetch(`${API_URL}/chat/chatbyid`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ userIDs }),   
        });

        if (!response.ok) {
            console.error(response);
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating chat:', error);
        return null;
    }
}

export const getMessagesForChat = async (chatID) => {
    try {
        const response = await fetch(`${API_URL}/chat/${chatID}`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching messages for chat:', error);
        return null;
    }
};

export const addMessageToChat = async (chatID, message, attachments = []) => {
    try {
        console.log('Adding message to chat:', chatID, message, attachments);
        const formData = new FormData();
        formData.append('content', message);

        // Append each attachment to the form data
        attachments.forEach((attachment) => {
            formData.append('attachments', attachment);
        });

        const response = await fetch(`${API_URL}/chat/${chatID}`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return await response.json();
    } catch (error) {
        console.error('Error adding message to chat:', error);
        return null;
    }
};

export const deleteMessageByID = async (messageID) => {
    try {
        const response = await fetch(`${API_URL}/chat/message/${messageID}`, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error deleting message:', error);
        return false;
    }
};
