import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { loadGoogleMapsApi } from "./util/loadGoogleMapsApi";
import './styles.css';
const apiKey = 'AIzaSyC94VAcY8k2icBoRPj4WrPirJNr332lq_A';

loadGoogleMapsApi(apiKey).then(() => {
    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById("root")
    );
}).catch(e => {
    console.error("Error loading Google Maps API:", e);
});
