// src/listingApi.js

import { API_URL } from '../util/config';

/**
 * Helper function to create FormData for a listing
 */
const buildFormData = (data, selectedFiles) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  selectedFiles.forEach((image) => {
    formData.append('images', image);
  });
  return formData;
};

/**
 * Common function to send listing data to a specified endpoint
 */
const sendListingRequest = async (formData, endpoint) => {
  try {
    const response = await fetch(`${API_URL}/${endpoint}`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    if (!response.ok) {
      console.error('Error response:', response);
      return response;
    }
    return response;
  } catch (error) {
    console.error('Error creating listing:', error);
    return false
  }
};

// Listing creation functions for each listing type

export const createAdoptionListing = async (listingData, selectedFiles) => {
  const formData = buildFormData(listingData, selectedFiles);
  // rename locationID to pickupLocationID
  formData.set('adoptOrFoster', 0);
  if (formData.get('locationID')) {
    formData.set('pickupLocationID', formData.get('locationID'));
    formData.delete('locationID');
  }
  return sendListingRequest(formData, 'adoptFoster');
};

export const createFosterListing = async (listingData, selectedFiles) => {
  const formData = buildFormData(listingData, selectedFiles);
  // rename locationID to pickupLocationID
  formData.set('adoptOrFoster', 1);
  if (formData.get('locationID')) {
    formData.set('pickupLocationID', formData.get('locationID'));
    formData.delete('locationID');
  }
  return sendListingRequest(formData, 'adoptFoster');
};

export const createFoundListing = async (listingData, selectedFiles) => {
  const formData = buildFormData(listingData, selectedFiles);
  // rename locationID to foundLocationID
  if (formData.get('locationID')) {
    formData.set('foundLocationID', formData.get('locationID'));
    formData.delete('locationID');
  }
  return sendListingRequest(formData, 'found');
};

export const createLostListing = async (listingData, selectedFiles) => {
  const formData = buildFormData(listingData, selectedFiles);
  if (formData.get('locationID')) {
    formData.set('lastSeenLocationID', formData.get('locationID'));
    formData.delete('locationID');
  }
    return sendListingRequest(formData, 'lost');
};

export const createRehomeListing = async (listingData, selectedFiles) => {
  const formData = buildFormData(listingData, selectedFiles);
  // rename locationID to currentLocationID
  if (formData.get('locationID')) {
    formData.set('currentLocationID', formData.get('locationID'));
    formData.delete('locationID');
  }
    return sendListingRequest(formData, 'rehome');
};
