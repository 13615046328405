import React, { useState, useEffect, useRef } from "react";
import { TextField, Button } from "@mui/material";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import rehome from "./RehomeListing.module.css";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { refreshToken } from '../../api/authApi';
import { createListing, uploadListingImages } from "../../api/rehomeApi"; // Import the new uploadListingImages function
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {GooglePlacesAutocompleteAdd} from "../../component/GoogleMaps/GooglePlacesAutocomplete";

export default function RehomeListing() {
    const [name, setName] = useState("");
    const [breed, setBreed] = useState("");
    const [typeOfAnimal, setTypeOfAnimal] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDOB] = useState(dayjs());  // When sending, change to string
    const [description, setDescription] = useState("");
    const [weight, setWeight] = useState("");
    const [currentLocationID, setCurrentLocationID] = useState("");
    const [specialNeeds, setSpecialNeeds] = useState("");
    const [dataUsed, setDataUsed] = useState(false);
    const [validListing, setValidListing] = useState(false);
    const [pickupDate, setPickupDate] = useState(dayjs());  // When sending, change to string
    const [foodreq, setFoodreq] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]); // State to store selected images
    const fileInputRef = useRef(null); // Ref for file input
    const today = dayjs();

    const navigate = useNavigate();
    const { user, setUser } = useUser();

    const states = [name, age, gender, typeOfAnimal, weight, pickupDate];

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            setSelectedFiles(files);
        }
        console.log(files);
    };

    const handleLoginFormClick = async () => {
        check(); // Check if all fields are filled
        setDataUsed(true);
        if (validListing === true) {
            // Send images along with the listing data
            const result = await createListing(
                user.userID,
                gender,
                description,
                name,
                typeOfAnimal,
                breed,
                age,
                dob,
                weight,
                specialNeeds,
                currentLocationID,
                pickupDate,
                foodreq,
                selectedFiles  // Pass selected images here
            );
            console.log(result);
    
            if (result) {
               navigate("../../home");
            }
        }
        setDataUsed(false);
    };
    
    
    const check = function () {
        const result = states.map(function (e) {
            if (e === "") {
                setValidListing(false);
                return false;
            }
            return true;
        });

        if (result.every(Boolean)) {
            setValidListing(true);
            return true;
        } else {
            setValidListing(false);
            return false;
        }
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleBreedChange = (event) => {
        setBreed(event.target.value);
    };

    const handleTypeOfAnimalChange = (event) => {
        setTypeOfAnimal(event.target.value);
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSpecialNeeds = (event) => {
        setSpecialNeeds(event.target.value);
    };

    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    };

    const handleCurrentLocationIDChange = (event) => {
        setCurrentLocationID(event.place_id);
    };

    const handleFoodChange = (event) => {
        setFoodreq(event.target.value);
    };

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login")
            }
        };

        checkToken();
    }, [setUser]);

    return (
        <div className={rehome.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
            </style>
            <div className={rehome.containerWithItems}>
                <div className={rehome.containerwithtext}>
                    <div className={rehome.title}>
                        Rehome Your Pet
                    </div>

                    <div className={rehome.containerwithitemfill}>
                        <TextField label="Name" size="medium" fullWidth onChange={handleNameChange} disabled={dataUsed} required />
                        <TextField label="Type" placeholder="Cat, Dog, Bird ..." size="medium" fullWidth onChange={handleTypeOfAnimalChange} disabled={dataUsed} required/>
                        <TextField label="Breed" size="medium" placeholder="Enter the breed of the pet" fullWidth onChange={handleBreedChange} disabled={dataUsed} />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="Date of Birth" defaultValue={today} maxDate={today} value={dob} onChange={(dob) => setDOB(dob)}/>
                        </LocalizationProvider>

                        <TextField label="Age" size="medium" fullWidth onChange={handleAgeChange} disabled={dataUsed} required />
                        <TextField label="Gender" size="medium" fullWidth onChange={handleGenderChange} disabled={dataUsed} required />
                        <TextField label="Weight" size="medium" fullWidth onChange={handleWeightChange} disabled={dataUsed} required />
                        <TextField label="Description" placeholder="Personality, preferences of your pet" size="medium" fullWidth onChange={handleDescriptionChange} disabled={dataUsed} multiline={true} rows={5} />
                        <TextField label="Special Needs" placeholder="List out any special needs of the pet" size="medium" fullWidth onChange={handleSpecialNeeds} disabled={dataUsed} multiline={true} rows={3} />
                        <TextField label="Food Requirements" placeholder="List out any food requirements of the pet" size="medium" fullWidth onChange={handleFoodChange} disabled={dataUsed} multiline={true} rows={3} />

                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DatePicker label="Pick Up By *" defaultValue={today} minDate={today} value={pickupDate} onChange={(pickupDate) => setPickupDate(pickupDate)} />
                        </LocalizationProvider>

                        <TextField
                            labe="Location"
                            size="medium"
                            fullWidth
                            disabled={dataUsed}
                            InputProps={{
                                inputComponent: GooglePlacesAutocompleteAdd,
                                inputProps: {
                                    onPlaceSelected: handleCurrentLocationIDChange,
                                },
                            }}
                        />

                        {/* Image Upload Button */}
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            disabled={dataUsed}>
                            Upload Images
                            <input
                                type="file"
                                ref={fileInputRef}
                                hidden
                                multiple // Allow selecting multiple files
                                onChange={handleFileChange} // Store files in state on change
                            />
                        </Button>
                        {selectedFiles.length > 0 && (
                            <div>{selectedFiles.length} image(s) selected</div> // Show image count
                        )}
                    </div>

                    <div className={rehome.buttonSubmit}>
                        <Button style={{ backgroundColor: "#555B6E" }} variant="contained" onClick={handleLoginFormClick} disabled={dataUsed}>SUBMIT</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
