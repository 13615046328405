import { API_URL } from '../util/config';

export const getServerStats = async () => {
    try {
        const response = await fetch(`${API_URL}/admin/stats`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return null;
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}
