import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box, List, ListItem, ListItemText, Avatar, Divider, Typography,
  TextField, IconButton, Button, Badge,
  Snackbar, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { AttachFile, Send, Delete } from '@mui/icons-material';
import Message from '../../component/Message/Message';
import { useUser } from '../../context/UserContext';
import { getChatsForUser, getMessagesForChat, addMessageToChat, deleteMessageByID, createChatByID, createChat } from '../../api/chatApi';
import NewChatDialog from '../../component/NewChatDialog/NewChatDialog';
import { GATEWAY_URL } from '../../util/config';
import { useParams } from 'react-router-dom';

// TODO: move to separate file
const ImagePreview = ({ file, onDelete }) => (
  <Box
    sx={{
      position: 'relative',
      width: 100,
      height: 100,
      borderRadius: '3px',
      overflow: 'hidden',
      backgroundColor: '#f0f0f0',
      flexShrink: 0,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src={URL.createObjectURL(file)}
      alt="preview"
      style={{
        width: '80%',
        objectFit: 'cover',
        borderRadius: '3px',
      }}
    />
    <IconButton
      size="small"
      sx={{
        position: 'absolute',
        top: 4,
        right: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '50%',
      }}
      onClick={onDelete}
    >
      <Delete fontSize="small" />
    </IconButton>
  </Box>
);

// TODO: UseParams to get userID, create a chat with that user and select that chat

const Messages = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { user } = useUser();
  const ws = useRef(null);
  const [openNewChatDialog, setOpenNewChatDialog] = useState(false);

  // if ;userID is in the url, create a chat with that user or select that chat
  const { userID } = useParams();


  const notificationAudio = useRef(new Audio('/notification.mp3'));
  const lastFetchedMessageID = useRef(null);

  // scroll to bottom when new message is received
  const messagesEndRef = useRef(null);
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleNewMessage = useCallback(
    (message) => {
      console.log(`comparing ${message.chatID} to ${selectedChat?.chatID}`);
      if (message.chatID === selectedChat?.chatID) {
        console.log('Received new message:', message);
        setMessages((prevMessages) => [...prevMessages, message]);
        notificationAudio.current.play();
      }

      // if chat isnt part of the current chat list, add it
      if (!chats?.find((chat) => chat.chatID === message.chatID)) {
        setChats((prevChats) => [
          {
            chatID: message.chatID,
            user: message.author,
            latestMessage: message,
            read: false,
          },
          ...prevChats,
        ]);
      }

      // update chat list
      setChats((prevChats) => {
        const updatedChats = prevChats?.map((chat) => {
          if (chat.chatID === message.chatID) {
            return {
              ...chat,
              latestMessage: message,
              read: chat.chatID === selectedChat?.chatID,
            };
          }
          return chat;
        });
        updatedChats.sort((a, b) => {
          if (!a.latestMessage) {
            return 1;
          }
          if (!b.latestMessage) {
            return -1;
          }
          // convert to date
          a = new Date(a.latestMessage.createdAt);
          b = new Date(b.latestMessage.createdAt);
          return b - a;
        });
        return updatedChats
      }
      );
    },
    [selectedChat]
  );


  useEffect(() => { 
    // maybe in the future make websocket part of the context
    // but for now, we can isolate it to just the messages page

    ws.current = new WebSocket(GATEWAY_URL);

    ws.current.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.current.onmessage = (event) => {
      console.log('WS EVENT:', event);
      const message = JSON.parse(event.data);
      console.log('Received message:', message);
      if (message.type === 'NEW_MESSAGE') {
        handleNewMessage(message.data);
      }
      if (message.type === 'DELETE_MESSAGE') {
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.messageID !== message.data));
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [handleNewMessage]);


  useEffect(() => {
    fetchChats();
  }, [user]);

  const fetchChats = async () => {
    try {
      const chatData = await getChatsForUser(user?.userID);

      // check error
      if (chatData.message) {
        setSnackbarMessage(chatData.message);
        setOpenSnackbar(true);
        return;
      }
      
      setChats(chatData);
  
      // Check if userID param exists
      if (userID) {
        const targetChat = chatData.find((chat) => chat.user?.userID === userID);
  
        if (targetChat) {
          setSelectedChat(targetChat);
          setChats((prevChats) =>
            prevChats?.map((chat) =>
              chat.chatID === targetChat.chatID ? { ...chat, read: true } : chat
            )
          );
          fetchMessages(targetChat.chatID);
          return; // Exit if userID match is found
        } {
          // If no match found, create a chat with the userID
          handleCreateChatByID(userID);
          return;
        }
      }
  
      // Default behavior if no userID or no match found
      if (chatData.length > 0) {
        setSelectedChat(chatData[0]);
        setChats((prevChats) =>
          prevChats?.map((chat) =>
            chat.chatID === chatData[0].chatID ? { ...chat, read: true } : chat
          )
        );
        fetchMessages(chatData[0].chatID);
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };
  

  const fetchMessages = async (chatID, playNotification = false) => {
    try {
      const data = await getMessagesForChat(chatID);
      if (playNotification && data.length > 0) {
        const latestMessage = data[data.length - 1];
        if (latestMessage.messageID !== lastFetchedMessageID.current && latestMessage.author.userID !== user?.userID) {
          notificationAudio.current.play();
          lastFetchedMessageID.current = latestMessage.messageID;
        }
      }
      console.log('Fetched messages:', data);
      setMessages(data);
    } catch (error) {
      setSnackbarMessage("Error fetching messages");
      setOpenSnackbar(true);
    }
  };

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    // mark as read
    setChats((prevChats) => prevChats?.map((c) => {
      if (c.chatID === chat.chatID) {
        return { ...c, read: true };
      }
      return c;
    }));
    setMessages([]);
    fetchMessages(chat.chatID);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() || selectedFiles.length > 0) {
      try {
        const savedMessage = await addMessageToChat(selectedChat.chatID, inputMessage, selectedFiles);
        console.log('Saved message:', savedMessage);
        if (savedMessage) {
          setMessages((prevMessages) => [...prevMessages, savedMessage]);
          setChats((prevChats) => {
            const updatedChats = prevChats?.map((chat) => {
              if (chat.chatID === selectedChat.chatID) {
                return { ...chat, latestMessage: savedMessage };
              }
              return chat;
            }
            );
            updatedChats.sort((a, b) => {
              if (!a.latestMessage) {
                return 1;
              }
              if (!b.latestMessage) {
                return -1;
              }
              // convert to date
              a = new Date(a.latestMessage.createdAt);
              b = new Date(b.latestMessage.createdAt);
              return b - a
            });
            return updatedChats;
          });
          setInputMessage('');
          setSelectedFiles([]);
        }
      } catch (error) {
        setSnackbarMessage("Error sending message");
        setOpenSnackbar(true);
      }
    }
  };

  const handleDeleteMessage = async (message) => {
    try {
      const success = await deleteMessageByID(message.messageID);
      if (success) {
        setMessages((prevMessages) => prevMessages.filter((msg) => msg.messageID !== message.messageID));
      }
    } catch (error) {
      setSnackbarMessage("Error deleting message");
      setOpenSnackbar(true);
    }
  };

  const handleDeleteSelectedFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleCreateChat = async (username) => {
    try {
      const response = await createChat([user?.username, username]);
      if (response.message) {
        setSnackbarMessage(response.message);
        setOpenSnackbar(true);
        return;
      }
      console.log('New chat:', response);
      setChats((prevChats) => [...prevChats, response]);
      setSelectedChat(response);
      fetchMessages(response.chatID);
      setOpenNewChatDialog(false);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  const handleCreateChatByID = async (userID) => {
    try {
      const response = await createChatByID([user?.userID, userID]);
      if (response.message) {
        return;
      }
      console.log('New chat:', response);
      setChats((prevChats) => [...prevChats, response]);
      setSelectedChat(response);
      fetchMessages(response.chatID);
      setOpenNewChatDialog(false);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };


  const getSecondaryLabel = (latestMessage, otherUser) => {
    if (!latestMessage) {
      return "";
    }
    var label = "";

    if (latestMessage.author !== user?.userID && latestMessage.author?.userID !== user?.userID) {
      label += otherUser + ": ";
    } else {
      label += "You: ";
    }
    if (latestMessage.attachments.length > 0) {
      label += latestMessage.attachments.length + " attachment(s)";
      return label;
    }
    label += latestMessage.content;
    return label;
  };

function getTimeAgo(timestamp) {

  if (!timestamp) {
      return "";
  }
  const now = new Date();
  const secondsPast = (now.getTime() - new Date(timestamp).getTime()) / 1000;

  if (secondsPast < 60) {
      return "Now";
  }
  if (secondsPast < 3600) {
      return `${Math.floor(secondsPast / 60)}m`;
  }
  if (secondsPast < 86400) {
      return `${Math.floor(secondsPast / 3600)}h`;
  }
  return `${Math.floor(secondsPast / 86400)}d`;
}

// trigger re-render of chat list every minute
useEffect(() => {
  const interval = setInterval(() => {
    setChats((prevChats) => [...prevChats]);
  }, 60000);
  return () => clearInterval(interval);
}, []);

  return (
    <Box display="flex" height="100vh" width="100%">
      {/* DM List Sidebar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        key={"topcenter"}
      />

      <Box width="25%" bgcolor="grey.200" p={2} sx={{ borderRight: '1px solid #ccc' }}>
        <Box display="flex" alignItems="center" mb={2}>
            <Avatar src={user?.profilePicture} sx={{ width: 50, height: 50, mr: 2 }} />
          <Typography variant="h6">{user?.username}</Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setOpenNewChatDialog(true)}
          sx={{ mb: 2 }}
        >
          New Chat
        </Button>

        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1">Direct Messages</Typography>
        {/* debug to see selected chat */}
        {/* <Typography variant="subtitle1">SELECTED: {selectedChat?.chatID}</Typography> */}
        <List>
          {chats?.map((chat) => (
            <ListItem
              key={chat.chatID}
              button
              selected={selectedChat?.chatID === chat.chatID}
              onClick={() => handleChatSelect(chat)}
              secondaryAction={
                <Typography variant="caption">
                  {getTimeAgo(chat.latestMessage?.createdAt)}
                </Typography>
              }
            >
              {/* TODO: Add unread badge */}
              {/* <Badge color="primary" variant="dot" invisible={chat.read}> */}
                <Avatar src={chat.user?.profilePicture} sx={{ mr: 2 }} />
              {/* </Badge> */}
              <ListItemText primary={chat.user?.username || "Unknown User"} secondary={getSecondaryLabel(chat.latestMessage, chat.user?.username)} />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Messaging Area */}
      <Box width="75%" p={2} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">

              <Avatar src={selectedChat?.user?.profilePicture} sx={{ width: 50, height: 50, mr: 2 }} />
              <Typography variant="h6">{selectedChat?.user?.username}</Typography>

        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Messages Display */}
        <Box
          flexGrow={1}

          display="flex"
          flexDirection="column"
          maxWidth="100%"
          overflow="auto"
          sx={{
            bgcolor: 'grey.100',
            borderRadius: 1,
            p: 2,
          }}
        >
          {messages?.map((message, index) => {
            const previousMessage = messages[index - 1];
            const isGrouped = previousMessage && previousMessage.author.userID === message.author.userID;

            return (

              <Message
                key={message.messageID}
                content={message.content}
                sender={message.author.username}
                timestamp={message.createdAt}
                isSender={message.author.userID === user?.userID}
                avatar={message.author.profilePicture}
                attachments={message.attachments}
                onDelete={() => handleDeleteMessage(message)}
                isGrouped={isGrouped}
              />
            );
          })}
          {/* Scroll-to-bottom anchor */}
          <div ref={messagesEndRef} />
        </Box>
        {/* Image Preview Row */}
        {selectedFiles.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              minHeight: 100,
              maxHeight: 120,
              padding: 1,
              gap: 2,
            }}
          >
            {selectedFiles.map((file, index) => (
              <ImagePreview
                key={index}
                file={file}
                onDelete={() => handleDeleteSelectedFile(index)}
              />
            ))}
          </Box>
        )}

        {/* Message Input and Icons */}
        <Box display="flex" gap={1} alignItems="center"
          sx={{
            mt: 2
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <IconButton color="primary" component="label">
            <input
              hidden
              accept="image/*"
              type="file"
              multiple
              onChange={handleFileChange}
            />
            <AttachFile />
          </IconButton>
          <IconButton color="primary" onClick={handleSendMessage}>
            <Send />
          </IconButton>
        </Box>
      </Box>

      {/* New Chat Dialog */}
      <NewChatDialog
        open={openNewChatDialog}
        onClose={() => setOpenNewChatDialog(false)}
        onCreateChat={handleCreateChat}
      />
    </Box>
  );
};

export default Messages;
