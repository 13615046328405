import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import CreateAccountPage from "./pages/CreateAccount/CreateAccount";
import RehomeListing from "./pages/RehomeListing/RehomeListing";
import HomePage from "./pages/HomePage/HomePage";
import UpdateAccountPage from "./pages/UpdateAccount/UpdateAccount";
import ViewAccountPage from "./pages/ViewAccount/ViewAccount";
import { UserProvider } from "./context/UserContext";
import Navbar from "./component/Navbar/Navbar";
import AdoptHomepage from "./pages/AdoptHomepage/AdoptHomepage";
import FosterHomepage from "./pages/FosterHomepage/FosterHomepage";
import LostPetsHomepage from "./pages/LostPetsHomepage/LostPetsHomepage";
import RehomeHomepage from "./pages/RehomeHomepage/RehomeHomepage";
import FoundHomepage from "./pages/FoundHomepage/FoundHomepage";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ResetForgottenPassword from "./pages/ResetForgottenPassword/ResetForgottenPassword";
import NeedVerification from "./pages/Verification/NeedToVerify";
import OrganizationNeedVerification from "./pages/Verification/OrganizationVerification";
import TestRoute from "./pages/TestRoute/TestRoute";
import ServerDown from "./pages/ServerDown/ServerDown";
import ViewAdoptListing from "./pages/ViewAdoptListing/ViewAdoptListing";
import PastListingsPage from "./pages/PastListings/PastListings";
import CurrentListingsPage from "./pages/CurrentListings/CurrentListings";
import ViewRehomeListing from "./pages/ViewRehomeListing/ViewRehomeListing";
import ReportBugs from "./pages/ReportBugs/ReportBugs";
import Footer from "./component/Footer/Footer";
import CreateListing from "./pages/CreateListing/CreateListing";
import ViewLostFoundPost from "./pages/ViewLostFoundPost/ViewLostFoundPost";
import UpdateListing from "./pages/UpdateListing/UpdateListing";
import Messages from "./pages/Messages/Messages";
import AdminStats from "./pages/AdminStats/AdminStats";
import BugReport from "./pages/AdminBugReport/AdminBugReport";
import PetReport from "./pages/ReportPetListing/ReportPetListing";
import ViewUserProfilePage from "./pages/ViewUserProfile/ViewUserProfile";
import TermsOfService from "./pages/TermsOfService/TermsofService";
import BehavioralGuidelines from "./pages/BehavioralGuidelines/BehavioralGuidelines";
import InstructionalGuidelines from "./pages/InstructionalGuidelines/InstructionalGuidelines";

const App = () => {
    return (
        <UserProvider>
            <div className="app-container">
                <Navbar />

                <div className="app-content">
                    <Routes>
                        <Route exact path="/" element={<HomePage />} />
                        <Route exact path="/home" element={<HomePage />} />
                        <Route exact path="/homepage" element={<HomePage />} />
                        <Route exact path="/login" element={<LoginPage />} />
                        <Route exact path="/createAccount" element={<CreateAccountPage />} />
                        <Route exact path="/rehome" element={<RehomeListing />} />
                        <Route exact path="/updateAccount" element={<UpdateAccountPage />} />
                        <Route exact path="/viewaccount" element={<ViewAccountPage />} />
                        <Route exact path="/adoptHomepage" element={<AdoptHomepage />} />
                        <Route exact path="/fosterHomepage" element={<FosterHomepage />} />
                        <Route exact path="/lostPetsHomepage" element={<LostPetsHomepage />} />
                        <Route exact path="/rehomeHomepage" element={<RehomeHomepage />} />
                        <Route exact path="/foundHomepage" element={<FoundHomepage />} />
                        <Route exact path="/resetPassword" element={<ResetPassword />} />
                        <Route exact path="/resetpassword/:id" element={<ResetForgottenPassword />} />
                        <Route exact path="/needverification" element={<NeedVerification />} />
                        <Route exact path="/orgverify" element={<OrganizationNeedVerification />} />
                        <Route exact path="/serverdown" element={<ServerDown />} />
                        <Route exact path="/test" element={<TestRoute />} />
                        <Route exact path="/viewadoptlisting/:id" element={<ViewAdoptListing />} />
                        <Route exact path="/pastListings" element={<PastListingsPage />} />
                        <Route exact path="/currentListings" element={<CurrentListingsPage />} />
                        <Route exact path="/viewrehomelisting/:id" element={<ViewRehomeListing />} />
                        <Route exact path="/reportbugs" element={<ReportBugs />} />
                        <Route exact path="/update-listing/:id" element={<UpdateListing />} />
                        <Route exact path="/create-listing" element={<CreateListing />} />
                        <Route exact path="/view-user-profile/:id" element={<ViewUserProfilePage />} />
                        <Route exact path="/view-lost-found-post/:id" element={<ViewLostFoundPost />} />
                        <Route exact path="/view-foster-listing/:id" element={<ViewAdoptListing />} />
                        <Route exact path="/messages" element={<Messages />} />
                        <Route exact path="/messages/:userID" element={<Messages />} />
                        <Route exact path="/admin-stats" element={<AdminStats/>} />
                        <Route exact path="/bug-reports" element={<BugReport/>} />
                        <Route exact path="/terms-of-service" element={<TermsOfService/>} />
                        <Route exact path="/report-pet-listing/:id" element={<PetReport/>} />
                        <Route exact path="/behavioral-guidelines" element={<BehavioralGuidelines/>} />
                        <Route exact path="/instructional-guidelines" element={<InstructionalGuidelines/>} />
                    </Routes>
                </div>

                <Footer />
            </div>
        </UserProvider>
    );
};

export default App;