// src/foundApi.js

import { API_URL } from '../util/config';

export const createListing = async (
    userID, gender, description, name, type,
    breed, age, dob, weight, specialNeeds, foundLocationID,
    foundDate, chipNumber, selectedFiles
) => {
    try {
        const formData = new FormData();
        formData.append('userID', userID);
        formData.append('gender', gender);
        formData.append('description', description);
        formData.append('name', name);
        formData.append('type', type);
        formData.append('breed', breed);
        formData.append('age', age);
        formData.append('dob', dob);
        formData.append('weight', weight);
        formData.append('specialNeeds', specialNeeds);
        formData.append('foundLocationID', foundLocationID);
        formData.append('foundDate', foundDate);
        formData.append('chipNumber', chipNumber);
        selectedFiles.forEach((image, index) => {
            formData.append(`images`, image);
        });

        const response = await fetch(`${API_URL}/found`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error creating listing:', error);
        return false;
    }
};

export async function getFoundListing(listingID) {
    try {
        const request = await fetch(`${API_URL}/found/${listingID}`, {
            method: 'GET',
            credentials: 'include',

        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export async function getAllFoundListings() {
    try {
        const request = await fetch(`${API_URL}/found/`, {
            method: 'GET',
            credentials: 'include',

        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export async function getAllActiveListings(query = null) {
    try {
        if (query) {
            const queryParams = new URLSearchParams(query);
            const request = await fetch(`${API_URL}/found/search-filter?${queryParams}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },

            });
            const data = await request.json();
            return data;
        }

        const request = await fetch(`${API_URL}/found/active`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export const updateFoundListing = async (formData, listingID) => {
    try {
        const response = await fetch(`${API_URL}/found/${listingID}`, {
            method: 'PATCH',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        throw error;
    }
};

export const deleteFoundListing = async (listingID) => {
    try {
        const response = await fetch(`${API_URL}/found/${listingID}`, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        throw error;
    }
};