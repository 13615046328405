// src/authApi.js

import { API_URL } from '../util/config';

export const pingServer = async () => {
    try {
        console.log("pinging server")
        const response = await fetch(`${API_URL}/`, {
            method: 'GET',
        });
        console.log(response)

        if (!response.ok) {
            console.error(response);
        }

        // if any response other than 500 level, return true
        if (response.status < 500) {
            return true;
        }
        return false;

    } catch (error) {
        throw error;
    }
}

export const loginUser = async (identification, password) => {
    try {
        const response = await fetch(`${API_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ identification: identification, password: password }),
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error; 
    }
};

export const refreshToken = async () => {
    try {
        const response = await fetch(`${API_URL}/auth/refresh`, {
            method: 'POST',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const logoutUser = async () => { 
    
    try {
        const response = await fetch(`${API_URL}/auth/logout`, {
            method: 'POST',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
        }

        return true;
    } catch (error) {
        throw error;
    }
}

export const protectedRoute = async () => {
    try {
        const response = await fetch(`${API_URL}/auth/protected`, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}