import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getRehomeListing } from "../../api/rehomeApi";
import css from "./ViewRehomeListing.module.css";
import {getAccountDetails} from "../../api/userApi";
import { Card, CardMedia, Button } from '@mui/material';
import {GeocodePlaceId, GoogleMapsDisplay} from "../../component/GoogleMaps/GoogleMapsDisplay";
import {useUser} from "../../context/UserContext";

export default function ViewRehomeListing() {
    const navigate = useNavigate();
    const [pet, setPet] = useState(null);
    const [arrayOfPetPic, setArrayOfPetPic] = useState(null);
    const {id} = useParams();
    const [number, setNumber] = useState(0);
    const [userName, setUserName] = useState(null);
    const { user, setUser } = useUser();
    const previous = "<";
    const next = ">";
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

    useEffect(() => {
        async function fetchData() {
            if (id != null){
                const response = await getRehomeListing(id);
                if (response.error !== "Listing Not Found") {
                    /*Updating the dates */
                    response.datePosted = new Date(response.datePosted).toLocaleDateString(undefined, options);
                    response.dateUpdated = new Date(response.dateUpdated).toLocaleDateString(undefined, options);
                    response.dob = new Date(response.dob).toLocaleDateString();

                    /*Ensuring everything is set at N/A if blank */
                    for (const value in response) {
                        if (response[value] === "") {
                            response[value] = "N/A";
                        }
                    }
                    /*Set everything into response */
                    setPet(response);
                    setArrayOfPetPic(response.picture);
                    const accountDetail = await getAccountDetails(response.userID);
                    if (accountDetail != null){

                        setUserName(accountDetail.name);
            
                    }
                }
            }
        }

        fetchData();

        return;
    }, [id, navigate]);

    if (!pet || !pet.name) {
        return  <div className={css.pageNotFound}>Page Not Found</div>;
    }

    const leftClick = async () => {
        if (arrayOfPetPic != null)
        {
            if (number - 1 < 0) {
                setNumber(arrayOfPetPic.length - 1);
            }
            else{
                setNumber(number-1);
            }
        }
    };

    const rightClick = async () => {
        if (arrayOfPetPic != null) {
            if (number  + 1 >= arrayOfPetPic.length) {
                setNumber(0);
            }
            else{
                setNumber(number + 1);
            }
        }

    };

    const handleEditListing = () => {
        //TODO: 
        //Add the path to navigate to for the update listing page
        navigate(`/update-listing/${id}`)
      }

    const handleReportListing = () => {
        navigate(`/report-pet-listing/${id}`)
    }

    const handleMessageOwner = () => {
        navigate(`/messages/${user.userID}`)
    }

    const {
        gender,
        description,
        name,
        type,
        breed,
        age,
        dob,
        weight,
        picture,
        specialNeeds,
        datePosted,
        dateUpdated,
        status,
        foodReq,
        currentLocationID,
    } = pet;
   

    const statusText = status === 1 ? 'Active' : status === 0 ? 'Inactive' : 'Deleted';
    return (
      <div className={css.page}>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
        </style>
        <div className={css.containerForInformation}>
            <div className={css.twocolumncontainer}>
                <div>
                    <div className={css.imageBox}>
                        {(arrayOfPetPic != null && arrayOfPetPic.length > 1) &&
                        <Card sx={{ maxWidth: '100%', maxHeight: '100%'}}>
                        <CardMedia
                            component="img"
                            image={arrayOfPetPic[number]}
                            alt={`${name}`}/>
                        </Card>}
                        {(arrayOfPetPic != null && arrayOfPetPic.length == 1) &&
                        <Card sx={{ maxWidth: '85%', maxHeight: '85%'}}>
                        <CardMedia
                            component="img"
                            image={arrayOfPetPic[number]}
                            alt={`${name}`}/>
                        </Card>}
                        {(arrayOfPetPic == null || arrayOfPetPic.length == 0) &&
                        <Card sx={{ maxWidth: '85%', maxHeight: '85%'}}>
                        <CardMedia
                            component="img"
                            src= "/Image_not_available.png"
                            alt={`Image Not Available`}/>
                        </Card>}
                    </div> 
                    {(arrayOfPetPic != null && arrayOfPetPic.length > 1) &&
                        <div className={css.buttonAlign}>
                        <Button style={{ backgroundColor: "#bee3db" }} color="#555B6E" size="large" onClick={leftClick} >
                            {previous}
                        </Button>
                        {number+1} / {arrayOfPetPic.length}
                        <Button style={{ backgroundColor: "#bee3db" }} color="#555B6E" size="large" onClick={rightClick}>
                            {next}
                        </Button>
                    </div>
                    }

                    <div className={css.tworow}>
                        <div className={css.editListingButton}>
                            {pet.userID === user.userID ?
                                <Button variant="contained"
                                        sx={{
                                            backgroundColor: '#89B0AE'
                                        }}
                                        onClick={handleEditListing}
                                >
                                    Edit Pet Information
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    className={css.messageButton}
                                    sx={{
                                        backgroundColor: '#89B0AE'
                                    }}
                                    onClick={handleMessageOwner}
                                >
                                    Message Owner
                                </Button>}
                        </div>
                        <div className={css.buttonSubmit}>
                            <Button style={{backgroundColor: "#555B6E"}} variant="contained" onClick={handleReportListing}>Report Pet Listing</Button>
                        </div>
                    </div>
                </div>
                <div className={css.containerForText}>
                    <div className={css.description}>
                    {pet.name}
                    </div>
                    <div className={css.descriptionBox}>
                        <div className={css.descriptionInformation}>
                            Description: {pet.description}
                        </div>  
                        <div className={css.descriptionInformation}>
                        Type: {pet.type}
                        </div>   
                        <div className={css.descriptionInformation}>
                        Breed: {pet.breed}
                        </div>   
                        <div className={css.descriptionInformation}>
                        Gender: {pet.gender}
                        </div>   
                        <div className={css.descriptionInformation}>
                        Date of Birth: {pet.dob}
                        </div>   
                        <div className={css.descriptionInformation}>
                        Age: {pet.age} years old
                        </div>   
                        <div className={css.descriptionInformation}>
                        Weight: {pet.weight} lb
                        </div> 
                        <div className={css.descriptionInformation}>
                        Food Requirements: {pet.foodReq}
                        </div>
                        <GeocodePlaceId placeId={pet.currentLocationID} />
                        <div className="google-map">
                            <GoogleMapsDisplay placeID={pet.currentLocationID} />
                        </div>

                    </div>
                  
                </div>
            </div>
            <div className={css.formatOfDates}>
                {(userName != null ) && <div className={css.dateUpdated}>
                    Posted By: {userName}
                </div>}
                <div className={css.dateUpdated}>
                    Posted On: {pet.datePosted}
                </div>
                <div className={css.dateUpdated}>
                    Last Updated: {pet.dateUpdated} 
                </div>
            </div>
            <div className={css.footer}>
            </div>
        </div>
      </div>
    );
}