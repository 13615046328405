import currentListings from "./CurrentListings.module.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAccountDetails, getUserAdoptFosterListings, getUserRehomeListings, getUserLostListings, getUserFoundListings } from "../../api/userApi";
import { useUser } from "../../context/UserContext";
import {refreshToken} from "../../api/authApi";
import { Button } from "@mui/material";



export default function CurrentListings() {
    const [myCurrentAdoptListings, setMyCurrentAdoptListings] = useState("");
    const [myCurrentFosterListings, setMyCurrentFosterListings] = useState("");
    const [myCurrentRehomeListings, setMyCurrentRehomeListings] = useState("");
    const [myCurrentLostListings, setMyCurrentLostListings] = useState("");
    const [myCurrentFoundListings, setMyCurrentFoundListings] = useState("");
    const { user, setUser } = useUser();
    const navigate = useNavigate();

    // Type: 0 means adopt listing, status: 1 means active/current listing
    const [adoptUserData, setAdoptUserData] = useState({
        type: 0,
        status: 1,
    });

    // Type: 1 means foster listing, status: 1 means active/current listing
    const [fosterUserData, setFosterUserData] = useState({
        type: 1,
        status: 1,
    });

    // Status: 1 means active listings (this is for rehome/lost/found listings)
    const [userData, setUserData] = useState({
        status: 1,
    });

    const Pets = (props) => (
            <div>
                <div className={currentListings.overallPetPost}>
                    <Link className={currentListings.petPost} to={navigateListing(props)}>
                        {props.pet.picture[0] ? (
                            <img
                                src={props.pet.picture[0]}
                                className={currentListings.petPicture}
                                alt="Pet Image"
                            />
                        ) : (
                            <img
                                src= "/Image_not_available.png"
                                alt="Image Not Available"
                                className={currentListings.petPicture}
                            />
                        )}
                        <div className={currentListings.containerPetPostText}>
                            {props.pet.name}
                        </div>
                    </Link>

                    <div className={currentListings.buttonSubmit}>
                        <Button style={{ backgroundColor: "#aaf0d1", fontFamily: "Koulen", color: "black", fontSize: 20, borderRadius: 10}} variant="contained" onClick={() => {handleEditButtonClick(props)}}>Edit Pet Info</Button>
                    </div>
                </div>
            </div>
    );

    function navigateListing(props) {
        if (props.pet.listingType === "AdoptFosterListing") {
            /* 
             * Navigate to adopt listing if adoptOrFoster is 0. 
             * Navigate to foster listing if adoptOrFoster is 1. 
             */
            if (props.pet.adoptOrFoster === 0) {
                return `/viewadoptlisting/${props.pet.listingID}`;
            }
            else {
                return `/view-foster-listing/${props.pet.listingID}`;
            }
        }
        else if (props.pet.listingType === "RehomeListing") {
            return `/viewrehomelisting/${props.pet.listingID}`;
        }
        else if (props.pet.listingType === "LostListing") {
            return `/view-lost-found-post/${props.pet.listingID}`;
        }
        else if (props.pet.listingType === "FoundListing") {
            return `/view-lost-found-post/${props.pet.listingID}`;
        }
    }

    const handleEditButtonClick = (props) => {
        navigate(`../../update-listing/${props.pet.listingID}`);
    }

    useEffect(() => {
        async function getMyCurrentAdoptListings() {   
            if (user != null){                
                const data = await getUserAdoptFosterListings(user.userID, adoptUserData);
                setMyCurrentAdoptListings(data);
            }
        }
        getMyCurrentAdoptListings();
        return;
    }, [adoptUserData, user]);

    useEffect(() => {
        async function getMyCurrentFosterListings() {
            if (user != null){                
                const data = await getUserAdoptFosterListings(user.userID, fosterUserData);
                setMyCurrentFosterListings(data);
            }
        }
        getMyCurrentFosterListings();
        return;
    }, [fosterUserData, user]);

    useEffect(() => {
        async function getMyCurrentRehomeListings() {
            if (user != null){                
                const data = await getUserRehomeListings(user.userID, userData);
                setMyCurrentRehomeListings(data);
            }
        }
        getMyCurrentRehomeListings();
        return;
    }, [userData, user]);

    useEffect(() => {
        async function getMyCurrentLostListings() {
            if (user != null){                
                const data = await getUserLostListings(user.userID, userData);
                setMyCurrentLostListings(data);
            }
        }
        getMyCurrentLostListings();
        return;
    }, [userData, user]);

    useEffect(() => {
        async function getMyCurrentFoundListings() {
            if (user != null){                
                const data = await getUserFoundListings(user.userID, userData);
                setMyCurrentFoundListings(data);
            }
        }
        getMyCurrentFoundListings();
        return;
    }, [userData, user]);


    function getCurrentAdoptListings() {
        return myCurrentAdoptListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getCurrentFosterListings() {
        return myCurrentFosterListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getCurrentRehomeListings() {
        return myCurrentRehomeListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getCurrentLostListings() {
        return myCurrentLostListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function getCurrentFoundListings() {
        return myCurrentFoundListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function currentAdoptListingsOrNot() {
        if (myCurrentAdoptListings.length === 0) {
            return (
                <div className={currentListings.noListingsText}>
                    No Adopt Listings
                </div>
            );
        } else {
            return (
                <ul className={currentListings.listOfPets}>
                    {getCurrentAdoptListings()}
                </ul>
            );
        }
    }

    function currentFosterListingsOrNot() {
        if (myCurrentFosterListings.length === 0) {
            return (
                <div className={currentListings.noListingsText}>
                    No Foster Listings
                </div>
            );
        } else {
            return (
                <ul className={currentListings.listOfPets}>
                    {getCurrentFosterListings()}
                </ul>
            );
        }
    }

    function currentRehomeListingsOrNot() {
        if (myCurrentRehomeListings.length === 0) {
            return (
                <div className={currentListings.noListingsText}>
                    No Rehome Listings
                </div>
            );
        } else {
            return (
                <ul className={currentListings.listOfPets}>
                    {getCurrentRehomeListings()}
                </ul>
            );
        }
    }

    function currentLostListingsOrNot() {
        if (myCurrentLostListings.length === 0) {
            return (
                <div className={currentListings.noListingsText}>
                    No Posts for Lost Pets
                </div>
            );
        } else {
            return (
                <ul className={currentListings.listOfPets}>
                    {getCurrentLostListings()}
                </ul>
            );
        }
    }

    function currentFoundListingsOrNot() {
        if (myCurrentFoundListings.length === 0) {
            return (
                <div className={currentListings.noListingsText}>
                    No Posts for Found Pets
                </div>
            );
        } else {
            return (
                <ul className={currentListings.listOfPets}>
                    {getCurrentFoundListings()}
                </ul>
            );
        }
    }

    return (
        <div className={currentListings.page}> 
            <div className={currentListings.listingsContainer}>
                <div className={currentListings.welcomebacktext}>
                    My Current Listings:
                </div>

                <div className={currentListings.petListingsContainer}>
                    <div className={currentListings.noListingsText}>
                        <b>Adopt Listings:</b>
                    </div>
                    {currentAdoptListingsOrNot()}

                </div>

                <div className={currentListings.petListingsContainer}>
                    <div className={currentListings.noListingsText}>
                        <b>Foster Listings:</b>
                    </div>
                    {currentFosterListingsOrNot()}
                </div>

                <div className={currentListings.petListingsContainer}>
                    <div className={currentListings.noListingsText}>
                        <b>Rehome Listings:</b>
                    </div>
                    {currentRehomeListingsOrNot()}
                </div>

                <div className={currentListings.petListingsContainer}>
                    <div className={currentListings.noListingsText}>
                        <b>Lost Pet Posts</b>
                    </div>
                    {currentLostListingsOrNot()}
                </div>

                <div className={currentListings.petListingsContainer}>
                    <div className={currentListings.noListingsText}>
                        <b>Found Pet Posts:</b>
                    </div>
                    {currentFoundListingsOrNot()}
                </div>
            </div>
        </div>
    );
}