import React, { useState, useEffect } from "react";
import css from "./AdminStats.module.css";
import { useUser } from '../../context/UserContext';
import { getServerStats } from "../../api/adminApi";

//TODO: GET THE STATS FOR THE SERVER

export default function AdminStats() {
    const [isAdmin, setIsAdmin] = useState(false);
    const stats = [];
    const { user, setUser } = useUser();
    const [serverStats, setServerStats] = useState(null);
    useEffect(() => {
        console.log(user);
        if (user != null) {
            if (user.accountType == 0)
                {
                    setIsAdmin(true);

                    // get the server stats
                    getServerStats()
                        .then((response) => {
                            console.log(response);
                            setServerStats(response);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
                else{
                    setIsAdmin(false);
                }
        }
    }, [user]);

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            {(!isAdmin) && 
                <div className={css.pageNotAvailable}>
                    This page is unavailable
                </div>}
            {(isAdmin) && 
                <div className={css.items}>
                    <div className={css.title}>
                            Admin Page
                    </div>
                    <div className={css.backgroundForItem}>
                        <div className={css.containerWithItem}>
                            <div className={css.text}>
                                Load: {serverStats?.cpuLoad?.loadPercentage}%
                            </div>
                            <div className={css.text}>
                                Uptime: {serverStats?.uptime?.uptimeFormatted}
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    );
} 