import resetPassword from "./ResetPassword.module.css";
import { requestPasswordReset } from "../../api/userApi";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { TextField, Alert, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Snackbar from "@mui/material/Snackbar";

export default function ResetPassword() {
    const [snackMessage, setSnackMessage] = useState("");
    const [email, setEmail] = useState([]);
    const navigate = useNavigate();
    const [emailSubmitSuccess, setEmailSubmitSuccess] = useState(false);
    const [open, setOpen] = useState(false);

    const handleResetPasswordClick = async () => {
        if (String(email).match("[A-z0-9]+@[A-z0-9]+.[A-z0-9]+")) {
            /* TODO: ERROR HANDLING? */
            
            setOpen(true);

            const response = await requestPasswordReset(email);
            console.log(response);
        } else {
            setSnackMessage("Email is not a valid email");
            handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const [deleteState, setDeleteState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });

    const { vertical, horizontal, openSnack } = deleteState;

    const handleClickAlert = () => {
        setDeleteState({ ...deleteState, openSnack: true });
    };

    const handleCloseAlert = () => {
        setDeleteState({ ...deleteState, openSnack: false });
    };

    const handleClose = () => {
        setOpen(false);
        setEmailSubmitSuccess(true);
        setTimeout(() => {
            navigate("../../Login");
        }, 5000);
    };

    return (
        <div className={resetPassword.page}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnack}
                onClose={handleCloseAlert}
                message={snackMessage}
                key={vertical + horizontal}
            />
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={resetPassword.containerWithItems}>
                <div className={resetPassword.containersignin}>
                    <div className={resetPassword.signintext}>
                        RESET PASSWORD
                    </div>
                    <div className={resetPassword.containerEachItem}>
                        <div className={resetPassword.textContainer}>
                            Email:     
                       </div>
                        <TextField 
                            fullWidth
                            className={resetPassword.textfield}
                            variant="outlined" size="small"
                            onChange={(event)=>handleEmailChange(event)}
                        >
                        </TextField>
                    </div>
                    <Button 
                        style={{backgroundColor:"#555B6E"}} 
                        variant="contained" 
                        onClick={handleResetPasswordClick}
                    >
                        SUBMIT
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>
                            {"Password reset email has been sent!"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {emailSubmitSuccess ?
                        <Alert icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success">
                            Please check your email for further instructions to reset your password.
                            You will be redirected to login in a few seconds.
                        </Alert>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
}