import homepagecss from "./HomePage.module.css";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PersonIcon from '@mui/icons-material/Person';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Footer from "../../component/Footer/Footer";
import { useNavigate } from "react-router-dom";
import React from 'react';

export default function HomePage() {
    const navigate = useNavigate();

    const options = [
        { label: "ADOPT", icon: <HandshakeIcon sx={{ width: 60, height: 60 }} />, path: "../adoptHomepage" },
        { label: "FOSTER", icon: <PersonIcon sx={{ width: 60, height: 60 }} />, path: "../fosterHomepage" },
        { label: "LOST PETS", icon: <SearchRoundedIcon sx={{ width: 60, height: 60 }} />, path: "../lostPetsHomepage" },
        { label: "FOUND", icon: <LocationOnIcon sx={{ width: 60, height: 60 }} />, path: "../FoundHomepage" },
        { label: "REHOME", icon: <HomeRoundedIcon sx={{ width: 60, height: 60 }} />, path: "../rehomeHomepage" }
    ];

    const navigateTo = (path) => {
        navigate(path);
    };

    return (
        <div className={homepagecss.homepage}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <button className={homepagecss.logoButton} onClick={() => window.location.href = '/homepage'}>
                <div className={homepagecss.logoText}>PAWS</div>
                <img
                    src="paws-4-your-home-logo.png"
                    alt="Logo Image"
                    className={homepagecss.logoImage}
                />
                <div className={homepagecss.logoText}>YOUR HOME</div>
            </button>

            <div className={homepagecss.container}>
                <div className={homepagecss.chooseOption}>
                    <div className={homepagecss.centerText}>
                        WHAT DO YOU WANT TO DO?
                    </div>
                    <div className={homepagecss.iconContainer}>
                        {options.map((option, index) => (
                            <button
                                key={index}
                                className={homepagecss.optionButton}
                                onClick={() => navigateTo(option.path)}
                            >
                                {option.icon}
                                <div className={homepagecss.optionText}>{option.label}</div>
                            </button>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}
