import React, { useState, useEffect } from "react";
import css from "./BehavioralGuidelines.module.css";



export default function BehavioralGuidelines() {
    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.textInContainer}>
                <div className={css.title}>
                    Behavioral Guidelines
                </div>
                <div className={css.textmid}>
                    Last Updated: November 15, 2024
                </div>
                <div className={css.text}>
                        Welcome to Paws for Your Homes, a website created to make adopting, fostering, 
                        finding lost pets, and reuniting found pets an easier process. Before using our platform, please
                        carefully read and understand these Behavior Guidelines, as they ensure a 
                        positive and safe experience for all users. By using Paws for Your Home, you agree to
                        be bound by these guidelines. If you do not agree to these guidelines, please
                        refrain from using our services.
                </div>
                <div className={css.textcategory}>
                    Respect and Courtesy
                </div>  
                <div className={css.textcontent}>
                    Treat all users with respect and kindness, regardless of their background or opinions. 
                </div>
                <div className={css.textcontent}>
                    Avoid using offensive language, harassment, or discrimination in any form. 
                </div>
                <div className={css.textcategory}>
                    Honest and Accurate Postings
                </div> 
                <div className={css.textcontent}>
                Provide accurate and detailed descriptions for the postings and listing. 
                </div>
                <div className={css.textcontent}>
                    Avoid using offensive language, harassment, or discrimination in any form. 
                </div> 
                <div className={css.textcategory}>
                    Timely Communication
                </div> 
                <div className={css.textcontent}>
                    Respond promptly to messages and inquiries from members of community who's looking to adopt, foster or inquiry about a posting.
                </div>
                <div className={css.textcontent}>
                    If there exist an incident during the communication, inform the other party politely. 
                </div> 
                <div className={css.textcategory}>
                    Privacy and Safety
                </div> 
                <div className={css.textcontent}>
                    Conduct in-person pet transfers at safe locations, well-lit location if needed. 
                </div>
                <div className={css.textcontent}>
                    Avoid setting the location of pickup at unknown places when listing the pets for adoption, foster or rehome.
                </div> 
                <div className={css.textcategory}>
                    Intellectual Property and Copyright
                </div> 
                <div className={css.textcontent}>
                     Respect copyright laws and intellectual property rights when posting listings and content.
                </div>
                <div className={css.textcontent}>
                      Do not upload copyrighted materials without proper authorization. 
                </div>
                <div className={css.textcategory}>
                    Adherence to Local Laws
                </div>
                <div className={css.textcontent}>
                    Ensure you comply with all applicable local, state, and national laws and regulations when using our platform.
                </div>
                <div className={css.textcategory}>
                    Suspension or Removal
                </div>
                <div className={css.textcontent}>
                     Violation of these guidelines may lead to account suspension or removal from the platform.
                </div>
                <div className={css.footer}>
                
                </div>
            </div>
        </div>
    );
} 
