import css from "./NeedToVerify.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { checkUserVerification, requestNewUserVerification } from "../../api/userApi";
import { useUser } from '../../context/UserContext';

export default function LoginPage() {
    const navigate = useNavigate();
    const [verificationDate, setVerificationDate] = useState("");
    const [remainingTime, setRemainingTime] = useState(null);
    const { user } = useUser();

    useEffect(() => {

        const checkVerification = async () => {
            const response = await checkUserVerification();
            if (response && response.date) {
                console.log(response.date);
                setVerificationDate(response.date);
                const time = calculateRemainingTime(response.date);
                setRemainingTime(time);
            }
        };
        checkVerification();
    }, []); // Empty dependency array to run the effect only once

    useEffect(() => {
        console.log(user);
        if (user && user.userID && user.status !== 1) {
            navigate("/");
        }
        let timer;
        if (remainingTime) {
            timer = setInterval(() => {
                const time = calculateRemainingTime(verificationDate);
                if (time.total <= 0) {
                    clearInterval(timer);
                    setRemainingTime(null);
                } else {
                    setRemainingTime(time);
                }
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [remainingTime, verificationDate]);

    const calculateRemainingTime = (date) => {
        const verificationTime = new Date(date).getTime();
        const now = new Date().getTime();
        const total = 3 * 24 * 60 * 60 * 1000 - (now - verificationTime); // 3 days in milliseconds

        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        if (total > 0) {
            return { total, days, hours, minutes, seconds };
        } else {
            return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    };

    const handleNewVerification = async () => {
        const now = new Date().toISOString();
        console.log(now);
        setVerificationDate(now);
        const time = calculateRemainingTime(now);
        setRemainingTime(time);
        const response = await requestNewUserVerification();
    };

    const handleClick = () => {
        navigate("../../home");
    };

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.container}>
                <div className={css.containerItem}>
                    {remainingTime && remainingTime.total > 0 ? (
                        <>
                            <p>Please verify your account within:</p>
                            <p>
                                {remainingTime.days}d {remainingTime.hours}h {remainingTime.minutes}m {remainingTime.seconds}s
                            </p>
                            <Button style={{ backgroundColor: "#555B6E" }} variant="contained" onClick={handleNewVerification}>
                                Request Verification Code
                            </Button>
                        </>
                    ) : verificationDate ? (
                        <>
                            <p>Verification period has expired. Please request a new verification code.</p>
                            <Button style={{ backgroundColor: "#555B6E" }} variant="contained" onClick={handleNewVerification}>
                                Request Verification Code
                            </Button>
                        </>
                    ) : (
                        <>
                            <p>Click to send verification code.</p>
                            <div className={css.ButtonAlign}>
                                <Button style={{ backgroundColor: "#555B6E" }} variant="contained" onClick={handleNewVerification}>
                                    Request Verification Code
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
