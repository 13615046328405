import css from "./NeedToVerify.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useUser } from '../../context/UserContext';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadOrganizationVerification, checkOrganizationVerification } from '../../api/userApi';

export default function OrganizationVerification() {
    const navigate = useNavigate();
    const { user } = useUser();
    const [dataUsed, setDataUsed] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]); // State to store selected files
    const [fileUrls, setFileUrls] = useState([]); // To store retrieved file URLs
    const [error, setError] = useState(null); // To store error if any
    const [userStatus, setUserStatus] = useState(null); // To store the user's status
    const fileInputRef = useRef(null); // Ref for file input

    // File selection handler (store files in state)
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to Array
        if (files.length > 0) {
            setSelectedFiles(files); // Store selected files in state
        } else {
            setSelectedFiles([]); // Reset if no files
        }
        console.log(files);
    };

    // Fetch organization verification status and uploaded files
    useEffect(() => {
        console.log("Checking organization verification");
        checkOrganizationVerification()
            .then((response) => {
                if (response && response.fileUrls) {
                    setFileUrls(response.fileUrls); // Store the uploaded files' URLs
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user]);

    const handleSubmit = async () => {
        if (selectedFiles.length > 0) {
            const formData = new FormData();
            selectedFiles.forEach(file => {
                formData.append('files', file);
            });

            try {
                const response = await uploadOrganizationVerification(formData);
                console.log("Files uploaded successfully:", response);
                setDataUsed(true);
            } catch (error) {
                console.error("Error uploading files:", error);
            }
        } else {
            console.log("No files selected");
        }
    };

    // Render status if user status is 4 and files are uploaded
    const renderStatus = () => {
        if (fileUrls.length > 0 && userStatus === 4) {
            return <div>Status: Pending Approval</div>;
        }
        if (fileUrls.length <= 0 && userStatus === 4) {
            return <div>Status: Files not uploaded</div>;
        }
        if (userStatus === 0) {
            return <div>Status: Approved</div>;
        }
        return null;
    };

    // Render the count of uploaded files
    const renderFileCount = () => {
        if (fileUrls.length > 0) {
            return <div>Uploaded Files: {fileUrls.length}</div>;
        }
        return <div>No files uploaded.</div>;
    };

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.container}>
                <div className={css.containerItem2}>
                    <div>
                        Upload documents to verify your organization
                    </div>

                    <div>
                        <Button
                            component="label"
                            variant="contained"
                            startIcon={<CloudUploadIcon />}
                            disabled={dataUsed}>
                            Upload files
                            <input
                                type="file"
                                ref={fileInputRef}
                                hidden
                                multiple // Allow selecting multiple files
                                onChange={handleFileChange} // Store files in state on change
                            />
                        </Button>
                        {selectedFiles.length > 0 && (
                            <div>{selectedFiles.length} file(s) selected</div> // Show file count
                        )}
                    </div>

                    <div>
                        <Button
                            style={{ backgroundColor: "#555B6E" }}
                            variant="contained"
                            onClick={handleSubmit} // Trigger the upload on submit
                            disabled={dataUsed || selectedFiles.length === 0}> {/* Disable if no files */}
                            SUBMIT
                        </Button>
                    </div>

                    {/* Render the status and file count */}
                    <div>
                        {renderStatus()}
                        {renderFileCount()}
                    </div>

                    {/* Display any errors */}
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
            </div>
        </div>
    );
}
