import updateAccountCSS from "./UpdateAccount.module.css";
import { useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import {refreshToken} from "../../api/authApi";
import {deleteAccount, getAccountDetails, requestPasswordReset, updateAccount, setImagePreview} from "../../api/userApi";
import {useUser} from "../../context/UserContext";
import Snackbar from "@mui/material/Snackbar";

export default function UpdateAccountPage() {
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [oldFormData, setOldFormData] = useState({
        name: "",
        username: "",
        email: "",
        accountType: "",
        password: "",
        description: "",
        profilePicture: "",
        phoneNumber: "",
    })
    const [formData, setFormData] = useState({
        name: "",
        username: "",
        email: "",
        accountType: "",
        password: "",
        description: "",
        profilePicture: "",
        phoneNumber: "",
    });
    const { user, setUser } = useUser();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            console.log(res.user);

            if (res.user === undefined) {
                navigate("../../login");
            } else {
                const userDetails = await getAccountDetails(res.user.userID);
                setOldFormData(userDetails);
                setFormData(userDetails);
            }

        };

        checkToken();
    }, [setUser]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Only update the form data if the value is present and not empty
        if (value !== undefined) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdateAccountClick = async () => {
        handleClose();

        if (newPassword === confirmPassword) {
            const newForm = {};
            for (const key in formData) {
                if (oldFormData[key] !== formData[key]) {
                    newForm[key] = formData[key];
                }
            }

            // Check username, name, email, account type exist before updating
            if ((formData.username !== undefined) && (formData.username.trim() !== "") && 
                (formData.name !== undefined) && (formData.name.trim() !== "") && 
                (formData.email !== undefined) && (formData.email.trim() !== "") &&
                (formData.accountType !== undefined)) {
                try {
                    let result = await updateAccount(user.userID, newForm);
                    if (result)
                    {
                        setSnackMessage("Update Success! Changes Saved!");
                        handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
                    }
                    else {
                        setSnackMessage("Update not successful!");
                        handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
                    }
                }
                catch(error) {
                    console.log(error);
                    setSnackMessage("Update not successful!");
                    handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
                }
            }
            else {

                setSnackMessage("Username, name, or password fields are missing");
                handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
            }
        } else {
            setSnackMessage("Passwords do not match");
            handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
        }
    };

    const handleDeleteAccountClick = () => {
        if (user.userID) {
            const res = deleteAccount(user.userID);
            console.log("Deleted");
            setTimeout(() => {
                setUser(null);
                navigate("/");
            }, 2000);
        }
    };

    const [deleteState, setDeleteState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });

    const { vertical, horizontal, openSnack } = deleteState;

    const handleClickAlert = () => {
        console.log("Should hit here")
        setDeleteState({ ...deleteState, openSnack: true });
    };

    const handleCloseAlert = () => {
        setDeleteState({ ...deleteState, openSnack: false });
    };

    const handleAccountTypeChange = (event) => {
        const value = event.target.value;

        if (value === "privateUser") {
            setFormData({
                ...formData,
                accountType: 1,
            });
        }   
        else if (value !== undefined) {
            setFormData({
                ...formData,
                accountType: 2,
            });
        }
    };

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        if (value === " ") {
            setSnackMessage("No spaces allowed in password");
            handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
        }
        else if (value !== undefined) {
            setFormData({
                ...formData,
                password: value,
            });
            setNewPassword(value);
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;

        if (value === " ") {
            setSnackMessage("No spaces allowed in password");
            handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
        }
        else if (value !== undefined) {
            setConfirmPassword(value);
        }
    };

    const cancelAccountChange = () => {
        navigate("../ViewAccount");
    };

    return (
        <div className={updateAccountCSS.page}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnack}
                onClose={handleCloseAlert}
                message={snackMessage}
                key={vertical + horizontal}
            />
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={updateAccountCSS.backgroundContainer}>
                <div className={updateAccountCSS.containerFitItems}>
                    <div className={updateAccountCSS.welcomebacktext}>
                        Update Account
                    </div>

                    <div className={updateAccountCSS.gridContainer}>
                        <div className={updateAccountCSS.textContainer}>
                            Name:
                        </div>
                        <TextField 
                            className={updateAccountCSS.textField}
                            label="Name" 
                            variant="filled"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />

                        <div className={updateAccountCSS.textContainer}>
                            Username:
                        </div>
                        <TextField 
                            className={updateAccountCSS.textField}
                            label="Username" 
                            variant="filled"
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                        />

                        <div className={updateAccountCSS.textContainer}>
                            Email:
                        </div>
                        <TextField 
                            className={updateAccountCSS.textField}
                            label="Email" 
                            variant="filled"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />

                        <div className={updateAccountCSS.textContainer}>
                            Phone number:
                        </div>
                        <TextField 
                            className={updateAccountCSS.textField}
                            label="Phone Number" 
                            variant="filled"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                        />

                        <div className={updateAccountCSS.textContainer}>
                            Account Type:
                        </div>
                        <FormControl 
                            variant="filled" 
                            className={updateAccountCSS.textField}
                        >
                            <InputLabel id="account-type-select-filled-label">Account Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="accountType"
                                value={formData.accountType === 1 ? "privateUser" : "organization"}
                                label="Account Type"
                                onChange={handleAccountTypeChange}
                            >
                                <MenuItem value="organization">Organization</MenuItem>
                                <MenuItem value="privateUser">Private User</MenuItem>
                            </Select>
                        </FormControl>

                        <div className={updateAccountCSS.textContainer}>
                            New password:
                        </div>
                        <TextField 
                            className={updateAccountCSS.textField}
                            label="New Password (leave blank if not changing)" 
                            variant="filled"
                            name="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={handlePasswordChange}
                        />

                        <div className={updateAccountCSS.textContainer}>
                            Confirm password:
                        </div>
                        <TextField 
                            className={updateAccountCSS.textField}
                            label="Confirm New Password" 
                            variant="filled"
                            name="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />

                        <div className={updateAccountCSS.textContainer}>
                            Description:
                        </div>
                        <TextField 
                            className={updateAccountCSS.textField}
                            label="Description" 
                            variant="filled"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                    </div>
                
                    <div className={updateAccountCSS.buttonsContainer}>
                        <Button 
                            variant="contained"
                            sx={{ 
                                backgroundColor: '#89B0AE' 
                            }}
                            onClick={cancelAccountChange}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#FFD6BA'
                            }}
                            onClick={() =>{
                                handleDeleteAccountClick();
                                setSnackMessage("Your account has been deleted.");
                                handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
                            }}
                        >
                            Delete Account
                        </Button>
                        <Button 
                            variant="contained"
                            sx={{ 
                                backgroundColor: '#89B0AE' 
                            }}
                            onClick={handleClickOpen}
                        >
                            Update Account
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Are you sure you want to update account?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button onClick={handleClose}>Disagree</Button>
                                <Button onClick={handleUpdateAccountClick} autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>          
    );
}