import * as React from 'react';
import {useEffect, useState} from 'react';
import rehome from "./RehomeHomepage.module.css";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {Button, CardMedia} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {GeocodePlaceId} from "../../component/GoogleMaps/GoogleMapsDisplay";

export default function ComplexRehomeGrid(petData) {
    const [petPic, setPetPic] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkToken = async () => {
            setPetPic(petData.pet.picture);
        };

        checkToken();
        }, [setPetPic]);

    
    const handleClick  = async () => {
        navigate(`/viewrehomelisting/${petData.pet.listingID}`)
    }

    return (
        <div className={rehome.gridColoring}>
            <Button classname={rehome.listingButton} onClick={handleClick} >
                <Paper
                    elevation={2}
                    square={false}
                    sx={(theme) => ({
                        p: 2.1,
                        ml: 2,
                        mr: 'auto',
                        my: 'auto',
                        maxWidth: 250,
                        minWidth: 250,
                        maxHeight: 300,
                        minHeight: 300,
                        flexGrow: 1,

                        backgroundColor: '#bee3db',
                        ...theme.applyStyles('dark', {
                            backgroundColor: '#1A2027',
                        }),
                    })}
                >
                    <Grid container spacing={2}>
                        <Grid item>
                            <div className={rehome.imageBox}>
                                {petData.pet.picture[0] ? (
                                    <div>
                                        <CardMedia
                                            sx={{ maxWidth: '250px', maxHeight: "200px", minHeight:"200px"}}
                                            component="img"
                                            image={petData.pet.picture[0]}
                                            alt={`${petData.pet.name}`}/>
                                    </div>
                                ) : (
                                    <div>
                                        <CardMedia
                                            sx={{ maxWidth: '100%', maxHeight: '100%', minHeight:"150px"}}
                                            component="img"
                                            src= "Image_not_available.png"
                                            alt={`Image Not Available`}/>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm container className={rehome.gridColoring}>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <div className={rehome.signintext}>{petData.pet.name}</div>
                                    <div className={rehome.gridColoring}>
                                    {/*{petData.pet.currentLocationID && petData.pet.currentLocationID !== "" && petData.pet.currentLocationID !== "N/A"
                                        ? <GeocodePlaceId placeId={petData.pet.currentLocationID} />
                                        : "Message for location"}*/}
                                    </div>
                                    <div className={rehome.tworow}>
                                        <div>
                                            Posted On: {new Date(petData.pet.datePosted).toLocaleDateString()}
                                        </div>
                                        <div>
                                            Last Updated: {new Date(petData.pet.dateUpdated).toLocaleDateString()}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Button>
            
        </div>
    );
}