// src/adoptFosterApi.js

import { API_URL } from '../util/config';

export const createListing = async (formData) => {
    try {
        const response = await fetch(`${API_URL}/adoptFoster`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        throw error;
    }
};

export async function getAdoptFosterListing(listingID) {
    try {
        const request = await fetch(`${API_URL}/adoptFoster/${listingID}`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export async function getAllAdoptFosterListings() {
    try {
        const request = await fetch(`${API_URL}/adoptFoster/`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export async function getAllAdoptListings() {
    try {
        const request = await fetch(`${API_URL}/adoptFoster/all/adopt`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export async function getAllActiveAdoptListings(query = null) {
    try {
        if (query) {
            const queryParams = new URLSearchParams(query);
            const request = await fetch(`${API_URL}/adoptFoster/all/adopt-search-filter?${queryParams}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },

            });
            const data = await request.json();
            return data;
        }
        const request = await fetch(`${API_URL}/adoptFoster/all/activeAdopt`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export async function getAllFosterListings() {
    try {
        const request = await fetch(`${API_URL}/adoptFoster/all/foster`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}

export async function getAllActiveFosterListings(query = null) {
    try {
        if (query) {
            const queryParams = new URLSearchParams(query);
            const request = await fetch(`${API_URL}/adoptFoster/all/foster-search-filter?${queryParams}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },

            });
            const data = await request.json();
            return data;
        }
        const request = await fetch(`${API_URL}/adoptFoster/all/activeFoster`, {
            method: 'GET',
            credentials: 'include',
        });
        const data = await request.json();
        return data;
    }
    catch (error) {
        throw error;
    }
}


export const updateAdoptFosterListing = async (formData, listingID) => {
    try {
        const response = await fetch(`${API_URL}/adoptFoster/${listingID}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        throw error;
    }
};

export const deleteAdoptFosterListing = async (listingID) => {
    try {
        const response = await fetch(`${API_URL}/adoptFoster/${listingID}`, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            console.error(response);
            return false;
        }

        return true;
    } catch (error) {
        throw error;
    }
};