import s from './TermsOfService.module.css';
import React from "react";


export default function TermsOfService() {
    return (
        <div className={s.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
            </style>
            <div className={s.textInContainer}>

                    <div className={s.title}>
                        <h1>Terms of Service</h1>
                        <h5>Last Updated: November 11, 2024</h5>
                    </div>
                    <div className={s.termsBody}>
                        <p>
                            Welcome to Paws For Your Home, a marketplace website dedicated to facilitating
                            the adoption, re-homing, and fostering of pets, as well as, finding lost pets.
                            Before using our platform, please
                            carefully read and understand these Terms of Service, as they govern
                            your use of our website and services. By using Paws For Your Home, you agree to
                            be bound by these Terms. If you do not agree to these Terms, please
                            refrain from using our services.
                        </p>
                        <ol>
                            <li>
                                <h6>Acceptance of Terms</h6>
                                <p>
                                    By using Paws For Your Home, you affirm that you are at least 18 years
                                    old or have the necessary legal capacity to enter into these Terms.
                                </p>
                            </li>
                            <li>
                                <h6>User Registration</h6>
                                <p>
                                    2.1. You may need to create an account to access certain features of
                                    Paws For Your Home. When registering, you agree to provide accurate, current,
                                    and complete information. You are responsible for maintaining the
                                    confidentiality of your account credentials.
                                </p>
                                <p>
                                    2.2. You are responsible for all activities that occur under your account.
                                    If you suspect any unauthorized access to your account, please notify us
                                    immediately.
                                </p>
                            </li>
                            <li>
                                <h6>Use of Paws For Your Home</h6>
                                <p>
                                    3.1. You agree to use Paws For Your Home in accordance with applicable laws and
                                    regulations, and in a manner that does not infringe on the rights of others
                                    or interfere with the operation of the platform.
                                </p>
                                <p>
                                    3.2. You are solely responsible for the content you post on Paws For Your Home,
                                    including listings, comments, and reviews. You must ensure that your content
                                    is accurate, lawful, and does not violate intellectual property or privacy rights.
                                </p>
                                <p>
                                    3.3. Paws For Your Home reserves the right to remove, edit, or refuse any content
                                    that
                                    violates these Terms or our policies.
                                </p>
                            </li>
                            <li>
                                <h6>Adopting, Fostering, Re-homing, and Finding pets</h6>
                                <p>
                                    4.1. Paws For Your Home is a platform for adopting and fostering. Pet owners and
                                    shelters are responsible for their own transactions, including payment,
                                    delivery, and communication.
                                </p>
                                <p>
                                    4.2. We recommend that users verify your ability to take care of a pet before
                                    adopting or fostering a pet.
                                </p>
                                <p>
                                    4.3. Paws For Your Home is not responsible for the accuracy, or legality of
                                    listings,
                                    or user behavior.
                                </p>
                            </li>
                            <li>
                                <h6>Fees and Payments</h6>
                                <p>
                                    5.1. Listing items on Paws For Your Home may be subject to fees. These fees are
                                    detailed in our
                                    separate
                                    Fee Schedule, which you agree to when listing items.
                                </p>
                                <p>
                                    5.2. Payments are processed through third-party payment providers, and you agree to
                                    their terms and
                                    conditions when making payments through Paws For Your Home.
                                </p>
                            </li>
                            <li>
                                <h6>Privacy</h6>
                                <p>
                                    6.1. Personal Information: When you register for an account, list items, or
                                    use certain features of Paws For Your Home, we may collect personal information
                                    such as your name, email address, and phone number.
                                </p>
                                <p>
                                    6.2. Information Sharing With Other Users: Certain information, such as your
                                    username and listings, may be visible to other users of Paws For Your Home.
                                </p>
                                <p>
                                    6.3. Data Security: We take security measures to protect your personal information
                                    from unauthorized access, alteration, disclosure, or destruction.
                                </p>
                                <p>
                                    6.4. Your Choices: You have the right to access, correct, or delete your personal
                                    information.
                                    You can update your account settings at any time.
                                </p>
                            </li>
                            <li>
                                <h6>Termination</h6>
                                <p>
                                    7.1. Paws For Your Home reserves the right to terminate or suspend
                                    your account and access to our services at our discretion,
                                    without prior notice, if you violate these Terms.
                                </p>
                            </li>
                            <li>
                                <h6>Intellectual Property</h6>
                                <p>
                                    8.1. All content and materials on Paws For Your Home, including logos,
                                    text, graphics, and software, are protected by copyright and other
                                    intellectual property laws. You agree not to copy, modify, or
                                    distribute any content without prior authorization.
                                </p>
                            </li>
                            <li>
                                <h6>Disclaimers</h6>
                                <p>
                                    9.1. Pets are raised by third parties,
                                    and we have no responsibility or liability for any aspects of
                                    pets except for Paws For Your Home Services.
                                </p>
                            </li>
                            <li>
                                <h6>Limitation of Liability</h6>
                                <p>
                                    10.1. To the extent permitted by law, Paws For Your Home and its affiliates
                                    will not be liable for any direct, indirect, incidental, special,
                                    consequential, or punitive damages, or any loss of profits or data.
                                </p>
                            </li>
                            <li>
                                <h6>Changes to Terms</h6>
                                <p>
                                    11.1. Paws For Your Home may update these Terms from time to time. Any changes
                                    will be posted on the website, and your continued use of our services
                                    constitutes acceptance of the updated Terms.
                                </p>
                            </li>
                        </ol>
                        <p>
                            By using Paws For Your Home, you acknowledge that you have read, understood, and agreed
                            to these Terms of Use. Thank you for choosing Paws For Your Home as your platform for
                            adopting, fostering, and re-homing.
                        </p>
                    </div>

            </div>
        </div>
    );
}