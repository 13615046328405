import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  background: transparent;
  font-family: 'Poppins', sans-serif;
  color: "black";
  font-size: 1em;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const GooglePlacesAutocompleteAdd = ({ onPlaceSelected }) => {
    const [autocomplete, setAutocomplete] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!autocomplete && window.google) {
            const autoCompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
                fields: ['place_id', 'geometry', 'name'],
                componentRestrictions: { country: "us" },
            });
            autoCompleteInstance.addListener('place_changed', () => {
                const place = autoCompleteInstance.getPlace();
                onPlaceSelected(place);
            });
            setAutocomplete(autoCompleteInstance);
        }
    }, [autocomplete, onPlaceSelected]);

    return <StyledInput ref={inputRef} type="text" placeholder="Location" />;
};

export const GooglePlacesAutocompleteEdit = ({originalPlaceId = "", onPlaceSelected }) => {

    const [autocomplete, setAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const [originalAddress, setOriginalAddress] = useState("");

    useEffect(() => {
        if (originalPlaceId !== "" && originalPlaceId !== "N/A") {
            const geocoder = new window.google.maps.Geocoder();
            geocoder
                .geocode({placeId: originalPlaceId})
                .then(({ results }) => {
                    if (results[0]) {
                        setOriginalAddress(results[0].formatted_address);
                    } else {
                        setOriginalAddress("No results found")
                    }
                })
                .catch((e) => {
                    window.alert("Geocoder failed due to: " + e)
                    setOriginalAddress(e);
                });
        }

        if (!autocomplete && window.google) {
            const autoCompleteInstance = new window.google.maps.places.Autocomplete(inputRef.current, {
                fields: ['place_id', 'geometry', 'name'],
                componentRestrictions: { country: "us" },
            });
            autoCompleteInstance.addListener('place_changed', () => {
                const place = autoCompleteInstance.getPlace();
                onPlaceSelected(place.place_id);
            });
            setAutocomplete(autoCompleteInstance);
        }
    }, [autocomplete, onPlaceSelected, originalPlaceId]);

    if (originalAddress === "") {
        return <StyledInput ref={inputRef} type="text" placeholder={"No Address Provided"} />;
    }
    return <StyledInput ref={inputRef} type="text" placeholder={originalAddress} />;
};
