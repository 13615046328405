import React, { useState, useEffect } from "react";
import instructionGuide from "./InstructionalGuidelines.module.css";

export default function InstructionalGuidelines() {
    return (
        <div className={instructionGuide.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={instructionGuide.textInContainer}>
                <div className={instructionGuide.title}>
                    Instructional Guidelines
                </div>
                <div className={instructionGuide.text}>
                    Welcome to the Instructional Guide for Paws for Your Home. Please make sure to carefully 
                    read through this guide in order to understand how to use our website.
                </div>
                <div className={instructionGuide.textcategory}>1. Sign Up</div>
                <div className={instructionGuide.textcontent}>
                    On Paws for Your Home, you can sign up for an account by clicking on the
                    profile icon in the top right corner, then clicking "Sign Up." Once you 
                    are navigated to the Create Account page, you can create an account by
                    filling out the form. If you belong to an organization such as a pet shelter,
                    you can select "Organization" for the account type. Otherwise, you should
                    select "Private User" for the account type. After creating an account, you 
                    are now able to sign in.
                </div>
                <div className={instructionGuide.textcategory}>2. Viewing Pets</div>
                <div className={instructionGuide.textcontent}>
                    On Paws for Your Home, you can browse pets for adopting, fostering, or rehoming 
                    by clicking the buttons on the start page to be redirected to their respective pages . You
                    can also view posts by users who have lost a pet or found a pet by navigating to their
                    respective pages through the start page.
                </div>

                <div className={instructionGuide.textcategory}>3. Searching and Filtering </div>
                <div className={instructionGuide.textcontent}>
                    If you need to search for a pet, on the listing pages/forums click on the searchbar and enter the
                    name, type, or breed of the pet you are searching for. You can also search by entering the username
                    of the person who made the pet listing/post. If you would like to filter through the pet listings/posts,
                    you can either filter by type, age, date updated, or date posted. You can also sort the listings
                    by date posted or date updated. When you are done inputting your search/filters, click the "Search" button
                    to apply them to the listings on the page.
                </div>
            
                <div className={instructionGuide.textcategory}>4. Listing/Posting a Pet</div>
                <div className={instructionGuide.textcontent}>
                    On Paws for Your Home, you can list a pet by clicking on the "Create Listing" button
                    in the header at the top of the page. Once you are navigated to the page to create
                    a listing, first select the type of listing you would like to create (e.g. adoption, foster, etc...).
                    Next, fill out all the required fields (fields with an asterisk). When writing the description,
                    you can use AI to help you write a better description. All you have to do is click the button, "Enhance
                    Your Description with AI" once you have written out a basic description for the pet.
                    You may also upload images of the pet you are creating a listing for. Once you have finished, click
                    "Submit." Now you should see the pet you have created a listing for on the website.
                </div>

                <div className={instructionGuide.textcategory}>5. Viewing Pet Listing/Post</div>
                <div className={instructionGuide.textcontent}>
                    After you click on a pet listing/post, you will be redirected to the View
                    Listing page, which has all of the information about the pet. This includes
                    fields such as the pet's name, description, type, breed, gender, and age. You may also
                    view the location of the pet on Google Maps. If you created the listing/post for the pet,
                    you will also see the "Edit Pet" button. When you click this button, you will be redirected
                    to a page where you can update the listing/post you made for the pet.
                </div>

                <div className={instructionGuide.textcategory}>6. Update or Delete a Pet Listing/Post</div>
                <div className={instructionGuide.textcontent}>
                    Once you are on the Update Listing page, you can update the information about the pet, then
                    click "Update Listing" to edit the pet's information. You can also mark a pet listing as resolved
                    if you no longer want it to show on the website (for example if you made a post for a pet on the "Found" page,
                    and the pet has already been reunited with its owner).
                    You can also delete a listing by clicking the "Delete Listing" button.
                </div>

                <div className={instructionGuide.textcategory}>7. Messaging</div>
                <div className={instructionGuide.textcontent}>
                    On Paws for Your Home, you can message other users. For example, if you'd like to adopt a pet that was 
                    listed on the Adopt page, you can message the user who created the pet listing. First, click
                    on "Messages," which is located in the header at the top of the page. You will then be redirected to  
                    the Messages page. Click on "New Chat" to start a new conversation with another user. Enter their username,
                    and you can now start messaging them! Here, you can also view your conversations with other users.
                </div>

                <div className={instructionGuide.textcategory}>8. Viewing/Editing/Deleting Your Account</div>
                <div className={instructionGuide.textcontent}>
                    If you would like to view or edit your account information, visit the 
                    My Account page by clicking your profile icon in the top left, then clicking 
                    the link to the page in the dropdown menu. From this page,
                    you can view your account information. If you'd like to edit or delete your account, click the
                    "Edit Account" button. If you'd like to view your listings, click the "Current Listings" or
                    "Past Listings" buttons to be navigated to their respective pages.
                </div>

                <div className={instructionGuide.textcategory}>9. My Listings Pages</div>
                <div className={instructionGuide.textcontent}>
                    To see all your listings, first navigate to the View Account page. Here, you can navigate to the
                    "Current Listings" page, which shows all the pet listings/posts you have created that are currently
                    still displayed on the website. You can also navigate to the "Past Listings" page, which shows
                    all the pet listings/posts you have made that have been marked as resolved.
                </div>

                <div className={instructionGuide.textcategory}>10. Report Bugs</div>
                <div className={instructionGuide.textcontent}>
                    While navigating Paws for Your Home, if you encounter any bugs while using our website, please
                    report the bug by clicking "Report Bugs," located in the footer at the bottom of all the pages. 
                    Please describe the bug/issue you encountered, and click the "Submit" button. Your feedback
                    is essential to helping us improve our website, and ensuring our users have a positive 
                    experience navigating our website.
                </div>

                <div className={instructionGuide.footer}></div>
            </div>
        </div>
    );
}
