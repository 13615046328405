import rehome from "./RehomeHomepage.module.css";
import { Card, Grid } from "@mui/material";
import ComplexGrid from "./ComplexRehomeGrid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { refreshToken } from "../../api/authApi";
import { getAccountDetails } from "../../api/userApi";
import { useUser } from "../../context/UserContext";

import * as React from "react";
import viewAccount from "../ViewAccount/ViewAccount.module.css";
import { getAllRehomeListings, getAllActiveListings } from "../../api/rehomeApi";
import ComplexRehomeGrid from "./ComplexRehomeGrid";
import { getAdoptFosterListing, getAllActiveAdoptListings, getAllAdoptListings } from "../../api/adoptFosterApi";
import { Button, Select, Paper, InputBase, Divider, IconButton, TextField, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function RehomeHomepage() {
    var script = document.createElement('script');
    script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js';
    document.getElementsByTagName('head')[0].appendChild(script);

    const { user, setUser } = useUser();
    const navigate = useNavigate();

    const [userData, setUserData] = useState("");
    const [imagePreview, setImagePreview] = useState(null);
    const [arrayOfPetPic, setArrayOfPetPic] = useState(null);
    const [petListings, setPetListings] = useState("");
    let counter = 1;

    const [dataUsed, setDataUsed] = useState(false);

    const sortedTypes = [
        'None',
        'Date Updated',
        'Date Posted'
    ];

    const types = [
        'All',
        'Cat',
        'Dog',
        'Hamster',
        'Ferret',
        'Bird',
        'Reptile',
        'Amphibian',
        'Rabbit',
        'Guinea Pig',
        'Fish',
        'Pet Livestock',
        'Pet Poultry',
    ];

    const orderTypes = [
        'Ascending',
        'Descending'
    ];

    const [filterData, setFilterData] = useState({
        search: "",
        username: "",
        type: "",
        datePosted: ["", ""],
        dateUpdated: ["", ""],
        age: [,],
        sortedField: "",
        sort: ""
    });

    const handleChange = (field, value) => {
        setFilterData((prev) => ({ ...prev, [field]: value }));
        if (value == types[0] || value == sortedTypes[0]) {
            setFilterData((prev) => ({ ...prev, [field]: "" }));
        }

    };

    const handleRangeChange = (field, value, index) => {
        setFilterData((prev) => {
            if (field == 'dateUpdated' || field == 'datePosted') {
                value = new Date(value);
            }
            const updatedRange = [...prev[field]];
            updatedRange[index] = value;
            return { ...prev, [field]: updatedRange };
        });
    };

    const handleFormClick = async () => {
        setFilterData((prev) => ({ ...prev, 'username': filterData['search'] }));
        var tempData = filterData;
        if (filterData.sort !== "") {
            if (filterData.sort === 'Ascending') {
                tempData.sort = 1;
            }
            if (filterData.sort === 'Descending') {
                tempData.sort = -1;
            }
        }
        if (filterData.sortedField !== "") {
            if (filterData.sortedField === 'Date Updated') {
                tempData.sortedField = "dateUpdated";
            }
            if (filterData.sortedField === 'Date Posted') {
                tempData.sortedField = "datePosted";
            }
        }
        setDataUsed(true);
        const result = await getAllActiveListings(tempData);

        //TODO - NEED TO ADD ERROR MESSAGES IF BACKEND HAS ERRORS 
        setPetListings(result);
        setDataUsed(false);
    }

    const handleReset = async () => {
        window.location.reload();
    }

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {
                const userDetails = await getAccountDetails(res.user.userID);
                setUserData(userDetails);
                setImagePreview(userDetails.profilePicture);

                const petDetails = await getAllActiveListings();
                setPetListings(petDetails);
            }
        };

        checkToken();
    }, [setUser, navigate, setPetListings]);

    function rehomePets() {
        if (petListings.length === 0) {
            return (
                <div className={rehome.page}>
                    No pets currently up for rehoming
                </div>
            );
        }
        else {
            return (
                <div className={rehome.listOfPets}>
                    <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={3}
                        justifyContent="space-evenly"
                    >
                        {petList()}
                    </Grid>


                </div>
            );
        }
    }

    function petList() {
        return petListings.map((pet) => {
            return (
                <ComplexRehomeGrid
                    pet={pet}
                />
            );
        });
    }

    return (
        <div className={rehome.page}>
            <div className={rehome.containerWithItems}>
                <div className={rehome.bigTitleText}>
                    Pets up for Rehoming
                </div>

                <div className={rehome.containerForFilters}>
                    <div className={rehome.innerFiltersContainer}>
                        <TextField
                            id="standard-basic"
                            label="Search"
                            variant="standard"
                            size="small"
                            className={rehome.filterTextField}
                            onChange={(e) => handleChange("search", e.target.value)}
                        />

                        <div className={rehome.filterContent}>
                            Filter By Type:
                            <div className={rehome.spacing}>
                                <TextField
                                    select
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={filterData.type}
                                    onChange={(e) => handleChange("type", e.target.value)}
                                    label="Type of Pet"
                                    disabled={dataUsed}
                                >
                                    {types.map((type) => (
                                        <MenuItem
                                            key={type}
                                            value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>

                        <div className={rehome.filterContent}>
                            Filter By Age:
                            <div className={rehome.twocolumn}>
                                <TextField
                                    size="small"
                                    label="Youngest Age"
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => handleRangeChange("age", e.target.value, 0)}
                                />
                                <TextField
                                    size="small"
                                    label="Oldest Age"
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => handleRangeChange("age", e.target.value, 1)}
                                />
                            </div>
                        </div>

                        <div className={rehome.filterContent}>
                            Filter by Date Posted:
                            <div className={rehome.twocolumn}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        views={['year', 'month', 'day']}
                                        onChange={(date) => handleRangeChange("datePosted", date, 0)}
                                        disabled={dataUsed}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        views={['year', 'month', 'day']}
                                        onChange={(date) => handleRangeChange("datePosted", date, 1)}
                                        disabled={dataUsed}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className={rehome.filterContent}>
                            Filter By Date Updated:
                            <div className={rehome.twocolumn}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        views={['year', 'month', 'day']}
                                        onChange={(date) => handleRangeChange("dateUpdated", date, 0)}
                                        disabled={dataUsed}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        views={['year', 'month', 'day']}
                                        onChange={(date) => handleRangeChange("dateUpdated", date, 1)}
                                        disabled={dataUsed}
                                        slotProps={{ textField: { size: 'small' } }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className={rehome.filterContent}>
                            <div>
                                Order By:
                                <Select
                                    size="small"
                                    value={filterData.sortedField}
                                    onChange={(e) => handleChange("sortedField", e.target.value)}
                                    fullWidth
                                    label="Order By"
                                    disabled={dataUsed}
                                >
                                    {sortedTypes.map((sortedTypes) => (
                                        <MenuItem
                                            key={sortedTypes}
                                            value={sortedTypes}
                                        >
                                            {sortedTypes}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        {(filterData.sortedField != "") &&
                            <div className={rehome.filterContent}>
                                <div>
                                    Order By:
                                    <Select
                                        size="small"
                                        value={filterData.sort}
                                        onChange={(e) => handleChange("sort", e.target.value)}
                                        fullWidth
                                        label="Order By"
                                        disabled={dataUsed}
                                    >
                                        {orderTypes.map((orderTypes) => (
                                            <MenuItem
                                                key={orderTypes}
                                                value={orderTypes}
                                            >
                                                {orderTypes}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>}

                        <div className={rehome.filterButtonSearch}>
                            <Button variant="contained" onClick={handleFormClick}>
                                Search
                            </Button>
                            <Button variant="contained" onClick={handleReset}>
                                Reset
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={rehome.containerWithListings}>
                    {rehomePets()}
                </div>
            </div>
        </div>
    );
}