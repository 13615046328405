import css from "./TestRoute.module.css";
import {Button } from "@mui/material";
import { useUser } from '../../context/UserContext';
import {useNavigate} from "react-router-dom";
import { protectedRoute } from "../../api/authApi";
import {getAdoptFosterListings, getAllUsernames, getUserAdoptFosterListings} from "../../api/userApi";
import {createListing, getLostListing} from "../../api/lostApi"
import {createBug, getBugsByBugID} from "../../api/bugApi";
import {getAllActiveAdoptListings, getAllActiveFosterListings} from "../../api/adoptFosterApi";
import {getAllActiveListings, getAllRehomeListings, getRehomeListing} from "../../api/rehomeApi";

// gender: Joi.string().allow('').required(),
//     description: Joi.string().allow('').required(),
//     name: Joi.string().allow('').required(),
//     type: Joi.string().allow('').required(),
//     age: Joi.number().allow('').required(),
//     weight: Joi.string().allow('').required(),
//     lastSeenLocationID: Joi.string().allow('').required(),
//     lastSeenDate: Joi.date().allow('').required(),
//     chipNumber: Joi.number().allow('').required(),

export default function TestRoute() {
    const navigate = useNavigate();
    // var data = {
    //     type: -1,
    //     status: -1
    // };
    const query = {
        search: "Angel",
        // username: "test",
        // type: null,
        // datePosted: ["2024-10-31", "2024-11-02"],
        // dateUpdated: ["2024-10-31", "2024-11-02"],
        // age: null,
    }

    const handleClick = async () => {
        // const response = await protectedRoute();
        // console.log(response);
        // const response = await getLostListing("bda07980-900d-11ef-bbad-41304ce414ca");
        // console.log(response)
        // const response = await createBug("5c0c3b35-c96e-41e9-abb7-5d98d5162d0c","TestBug");
        // const response = await getBugsByBugID("329eac60-9014-11ef-b3ee-81eec69b3a75");
        const response = await getAllActiveAdoptListings(query);
        // const response = await getRehomeListing("d8512270-9a03-11ef-824c-13b7a07729d0")
        console.log(response);
    }

    //Need to check that users need verification.

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.container}>
                <div className={css.containerItem}>
                    Test Route 
                </div>
                <div className={css.ButtonAlign}>
                    <Button style={{backgroundColor:"#555B6E"}} variant="contained" onClick={handleClick}>Protected Route</Button>
                </div>
            </div>
           
 
        </div>
    );
} 