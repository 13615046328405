import React, { useState, useEffect } from "react";
import { TextField, Button, Stack, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import viewAccount from "./ViewAccount.module.css";
import { useUser } from "../../context/UserContext";
import {logoutUser, refreshToken} from "../../api/authApi";
import { getAccountDetails, updateProfilePicture, getUserAdoptFosterListings, getUserRehomeListings, getUserLostListings, getUserFoundListings } from "../../api/userApi";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";

export default function ViewAccountPage() {
    const { user, setUser } = useUser();
    const [userData, setUserData] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // For confirmation dialog    
    const navigate = useNavigate();
    const [haveCurrentListings, setHaveCurrentListings] = useState(false);
    const [havePastListings, setHavePastListings] = useState(false);

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {
                const userDetails = await getAccountDetails(res.user.userID);
                setUserData(userDetails);
                setImagePreview(userDetails.profilePicture);
            }
        };

        checkToken();
    }, [setUser, navigate]);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            img.onload = () => {
                if (img.width > 1024 || img.height > 1024) {
                    setError("Image dimensions should not exceed 1024x1024 pixels.");
                    setSelectedFile(null);
                    setImagePreview(null);
                } else {
                    setSelectedFile(file);
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setImagePreview(reader.result);
                    };
                    reader.readAsDataURL(file);
                    setError(null);
                }
            };
            img.src = URL.createObjectURL(file);
        }
    };

    const handleAvatarClick = () => {
        // Check if user already has a profile picture
        if (user.profilePicture) {
            setOpenDialog(true);
        } else {
            document.getElementById("fileInput").click();
        }
    };

    const handleUploadClick = async () => {
        if (selectedFile) {
            try {
                await updateProfilePicture(selectedFile);
                

            } catch (error) {
                console.error("Image upload failed:", error);
            }
            const res = await refreshToken();
            setUser(res.user);
        }
    };

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            document.getElementById("fileInput").click();
        }
    };

    const handleSignUpFormClick = async () => {
        navigate("../../updateAccount");
    };

    const handleCurrentListingClick = async () => {
        navigate("../../currentListings");
    };

    const handlePastListingClick = async () => {
        navigate("../../pastListings");
    };

    const handleLogOutClick = async () => {
        await logoutUser();
        // navigate('/');
        setTimeout(() => {
            setUser(null);
            navigate("/");
        }, 2000);
    }

    const [state, setState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });

    const { vertical, horizontal, open } = state;

    const handleClickAlert = () => {
        console.log("Should hit here")
        setState({ ...state, open: true });
    };

    const handleCloseAlert = () => {
        setState({ ...state, open: false });
    };

    /**
     * 0 - Active
     * 1 - Active in Grace Period
     * 2 - Deactivated
     * 3 - Deleted
     **/
    function getAccountStatus() {
        const status = userData.status;
        switch (status) {
            case 0:
                return "Active";
            case 1:
                return "Active in Grace Period";
            case 2:
                return "Deactivated";
            case 3:
                return "Deleted";
        }
    }

    useEffect(() => {
        async function currentListingsExist() {
            // Type: 0 means adopt listing, status: 1 means active/current listing
            const adoptUserData = {
                type: 0,
                status: 1,
            };

            // Type: 1 means foster listing, status: 1 means active/current listing
            const fosterUserData = {
                type: 1,
                status: 1,
            };

            // Status: 1 means active listings (this is for rehome/lost/found listings)
            const otherListingsUserData = {
                status: 1,
            };

            if (user != null) {
                const currentAdoptListingsData = await getUserAdoptFosterListings(user.userID, adoptUserData);
                const currentFosterListingsData = await getUserAdoptFosterListings(user.userID, fosterUserData);
                const currentRehomeListingsData = await getUserRehomeListings(user.userID, otherListingsUserData);
                const currentLostListingsData = await getUserLostListings(user.userID, otherListingsUserData);
                const currentFoundListingsData = await getUserFoundListings(user.userID, otherListingsUserData);

                if ((currentAdoptListingsData.length !== 0) || (currentFosterListingsData.length !== 0) || 
                    (currentRehomeListingsData.length !== 0) || (currentLostListingsData.length !== 0) ||
                    (currentFoundListingsData.length !== 0)) {
                    setHaveCurrentListings(true);
                } 
                else {
                    setHaveCurrentListings(false);
                }
            }
        }
        currentListingsExist();
    });

    useEffect(() => {
        async function pastListingsExist() {
            // Type: 0 means adopt listing, status: 0 means past/inactive listing
            const adoptUserData = {
                type: 0,
                status: 0,
            };

            // Type: 1 means foster listing, status: 0 means past/inactive listing
            const fosterUserData = {
                type: 1,
                status: 0,
            };

            // Status: 0 means past/inactive listings (this is for rehome/lost/found listings)
            const otherListingsUserData = {
                status: 0,
            };

            if (user != null) {
                const pastAdoptListingsData = await getUserAdoptFosterListings(user.userID, adoptUserData);
                const pastFosterListingsData = await getUserAdoptFosterListings(user.userID, fosterUserData);
                const pastRehomeListingsData = await getUserRehomeListings(user.userID, otherListingsUserData);
                const pastLostListingsData = await getUserLostListings(user.userID, otherListingsUserData);
                const pastFoundListingsData = await getUserFoundListings(user.userID, otherListingsUserData);

                if ((pastAdoptListingsData.length !== 0) || (pastFosterListingsData.length !== 0) || 
                    (pastRehomeListingsData.length !== 0) || (pastLostListingsData.length !== 0) ||
                    (pastFoundListingsData.length !== 0)) {
                    setHavePastListings(true);
                } 
                else {
                    setHavePastListings(false);
                }
            }
        }
        pastListingsExist();
    });

    return (
        <div className={viewAccount.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
            </style>
            <div className={viewAccount.containerWithItems}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleCloseAlert}
                    message="You have been logged out"
                    key={vertical + horizontal}
                />
                <div className={viewAccount.containersignin}>
                    <div className={viewAccount.signintext}>View Account</div>
                    <Stack direction="row" spacing={6} alignItems="center">
                        <Avatar
                            alt={userData.name}
                            src={imagePreview}
                            sx={{ width: 100, height: 100, cursor: "pointer" }}
                            onClick={handleAvatarClick}
                        />
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileSelect}
                        />
                        {selectedFile && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUploadClick}
                            >
                                Upload
                            </Button>
                        )}
                    </Stack>
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            <div>Name:</div>
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            <div>{userData.name}</div>
                        </div>
                    </Stack>

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            <div>Username:</div>
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            <div>{userData.username}</div>
                        </div>
                    </Stack>

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            <div>Email:</div>
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            <div>{userData.email}</div>
                        </div>
                    </Stack>

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            <div>Phone number:</div>
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            <div>{userData.phoneNumber}</div>
                        </div>
                    </Stack>

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            <div>Account Type:</div>
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            <div>{userData.accountType === 1 ? "Private Account" : "Organization"}</div>
                        </div>
                    </Stack>

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            <div>Valid Account:</div>
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            <div>{getAccountStatus()}</div>
                        </div>
                    </Stack>

                    <Stack direction="row" spacing={6}>
                        <div className={viewAccount.viewAcc}>
                            {userData.description !== "" ? <div>Description:</div> : ""}
                        </div>
                        <div className={viewAccount.viewAccDetails}>
                            {userData.description !== "" ? <div>{userData.description}</div>: ""}
                        </div>
                    </Stack>

                    <div className={viewAccount.center}>
                        <Stack direction="row" spacing={2}>
                            {(haveCurrentListings === true) ?
                                <Button
                                    className={viewAccount.editAccountButton}
                                    sx={{
                                        backgroundColor: '#89B0AE'
                                    }}
                                    variant="contained"
                                    onClick={handleCurrentListingClick}>
                                    CURRENT LISTINGS
                                </Button> : null
                            }
                            {(havePastListings === true) ?
                                <Button
                                    className={viewAccount.editAccountButton}
                                    sx={{
                                        backgroundColor: '#89B0AE'
                                    }}
                                    variant="contained"
                                    onClick={handlePastListingClick}>
                                    PAST LISTINGS
                                </Button> : null
                            }
                            <Button
                                className={viewAccount.editAccountButton}
                                sx={{
                                    backgroundColor: '#89B0AE'
                                }}
                                variant="contained"
                                onClick={handleSignUpFormClick}>
                                EDIT ACCOUNT
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#FFD6BA'
                                }}
                                onClick={() =>{
                                    handleLogOutClick();
                                    handleClickAlert({ vertical: 'bottom', horizontal: 'center' });
                                }}
                            >
                                Log Out
                            </Button>
                        </Stack>
                    </div>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <Dialog
                open={openDialog}
                onClose={() => handleDialogClose(false)}
            >
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You already have a profile picture. Do you want to replace it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary">
                        Yes, Replace
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
