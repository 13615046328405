import React, { useState, useEffect } from "react";
import { TextField, Button, Alert } from "@mui/material";
import css from "./ReportPetListing.module.css";
import Snackbar from "@mui/material/Snackbar";
import { createReport } from "../../api/reportListingApi";
import { useUser } from "../../context/UserContext";
import { useParams, useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';

export default function ReportPetListing() {
    const [report, setReport] = useState("");
    const [dataUsed, setDataUsed] = useState(false);
    const [reportUsed, setReportUsed] = useState(true);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const {id} = useParams();

    const handleFormClick  = async () => {
        setDataUsed(true);
        if (report === "") {
            setDataUsed(false);
            setReportUsed(false);
            handleClickAlert();
        }
        else{
            try{
                console.log("Report is sending...");
                console.log(user);
                const data = await createReport(user.userID, id, report);
                console.log(data)

                if (!data.ok) { // if the data is not okay
                    setFailure(!data.ok);
                    setTimeout(() => {
                        navigate("/home");
                      }, 5000); 
                }
                setSuccess(data.ok);
                if (data.ok){
                    setTimeout(() => {
                        navigate("/home");
                      }, 5000); 
                }
                else{
                    
                }
            
            } catch (err) {
                console.error(err);
            }

            
        }
    }

    const handleReportTextField = (event) => {
        setReport(event.target.value);
    };

    const handleClickAlert = () => {
        setState({ ...state, open: true });
    }

    const handleCloseAlert = () => {
        setState({ ...state, open: false });
    };

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.containerwithitems}>
                <Snackbar
                        autoHideDuration={5000}
                        anchorOrigin={{ vertical, horizontal }}
                        open={open}
                        onClose={handleCloseAlert}
                        message="Please enter information about why the pet listing is inappropriate in the field below."
                        key={vertical + horizontal}
                    />
                <div className={css.title}>
                    Report Pet Listing
                </div>
                <div className={css.description}>
                    Please write the reason below why the listing is inappropriate. 
                </div>
                <div>
                    <TextField 
                        placeholder="Put your reason why its not valid...." 
                        size="medium" 
                        fullWidth 
                        onChange={handleReportTextField} 
                        disabled={dataUsed} 
                        multiline={true} 
                        rows={10} 
                        error={reportUsed === false ? true : false} 
                        required />
                </div>

                <div className={css.submitButton}>
                    <Button 
                        style={{ backgroundColor: "#555B6E" }} 
                        variant="contained" 
                        onClick={handleFormClick} 
                        disabled={dataUsed}
                        size="large">
                            SUBMIT
                    </Button>
                </div>
                {success && (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Your report has been successfully created! You will be redirected to the home page in 5 seconds.
                        </Alert>
                    )}

                {failure && (
                        <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
                            Your report was not created. Please redo the form. You will be redirected to the home page in 5 seconds.
                        </Alert>
                    )}  
            </div>
        </div>
    );
} 