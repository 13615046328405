import React, { useState, useEffect} from "react";
import { TextField, Button, Alert } from "@mui/material";
import css from "./ResetForgottenPassword.module.css";
import { loginUser } from "../../api/authApi";
import { useUser } from '../../context/UserContext';
import {useNavigate} from "react-router-dom";
import { resetPassword } from "../../api/userApi";
import { useParams } from "react-router-dom";

export default function LoginPage() {
    const { login } = useUser();
    const { id } = useParams();
    const [password, setPassword] = useState("");
    const [password2, matchPassword] = useState("");
    const [dataUsed, setDataUsed] = useState(false);
    const [match, setMatch] = useState(false) // If passwords match, then true
    const navigate = useNavigate();
    const [firstTime, setFirstTime] = useState(true);
    const [success, setSuccess] = useState(false); 
    
    const handleFormClick = async () => {
        setDataUsed(true);
        //Checking password are the same
        if (password === password2 && password != "")
        {
            setMatch(true);
            // TODO: HANDLE SUCCESS AND FAILURE
            const response = await resetPassword(id, password);
            if (response === true)
            {
                setSuccess(true);
                setTimeout(() => {
                    navigate("/login");
                }, 5000);
            } else {
                setSuccess(false);
            }
        } 
        else{
            setFirstTime(false);
            setMatch(false);
        }
        setDataUsed(false);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const checkPassword = (event) => {
        matchPassword(event.target.value);
    };
    

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={css.container}>
                <div className={css.containerItem}>
                    <div className={css.title}>
                       RESET PASSWORD
                    </div>
                    <TextField label="Password"
                                    type="password"
                                    variant="standard"
                                    size="medium"
                                    fullWidth
                                    disabled={dataUsed === true? true: false}
                                    onChange={(event) => handlePasswordChange(event)
                                    }
                            />
                    <TextField label="Confirm Password"
                                    type="password"
                                    variant="standard"
                                    size="medium" fullWidth
                                    disabled={dataUsed === true? true: false}
                                    onChange={(event) => checkPassword(event)}
                                    error = {!match && !firstTime}
                                    helperText = {!firstTime && !match && "Passwords do not match"}
                    />
                <Button style={{backgroundColor:"#555B6E"}} variant="contained" onClick={handleFormClick}>Confirm Change</Button>
                {success && <Alert severity="success">Password reset successful!</Alert>}
                </div>

            </div>
           
        </div>
    );
} 