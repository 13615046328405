import OpenAI from "openai";

const OPENAI_API_KEY = "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"
const openai = new OpenAI({apiKey: OPENAI_API_KEY, dangerouslyAllowBrowser: true});

export const enhanceText = async (text) => {
    const response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
            {
                "role": "user",
                "content": [
                    {
                        "type": "text",
                        "text":
                            `Rewrite the following text to be more readable, with short sentences
                             and better formatted with improved grammar and syntax:${text}`
                    }
                ]
            }
        ],
        max_tokens: 150,
        temperature: 0.7
    });

    return response.choices[0].message.content;
};

