import foster from "./FosterHomepage.module.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {getAllFosterListings, getAllActiveFosterListings, getAllActiveAdoptListings} from "../../api/adoptFosterApi";
import { useUser } from "../../context/UserContext";
import {GeocodePlaceId} from "../../component/GoogleMaps/GoogleMapsDisplay";

import {refreshToken} from "../../api/authApi";
import { useNavigate } from "react-router-dom"
import {getAccountDetails} from "../../api/userApi";
import adopt from "../AdoptHomepage/AdoptHomepage.module.css";
import {Button, MenuItem, Select, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

export default function FosterHomepage() {
    const [fosterListings, setFosterListings] = useState("");
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const [dataUsed, setDataUsed] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [userData, setUserData] = useState("");
    const sortedTypes = [
        'None',
        'Date Updated',
        'Date Posted'
    ];
    const orderTypes = [
        'Ascending',
        'Descending'
    ];
    const [filterData, setFilterData] = useState({
        search: "",
        username: "",
        type: "",
        datePosted:["",""],
        dateUpdated:["",""],
        age:[,],
        sortedField: "",
        sort:""
    });
    const types = [
        'All',
        'Cat',
        'Dog',
        'Hamster',
        'Ferret',
        'Bird',
        'Reptile',
        'Amphibian',
        'Rabbit',
        'Guinea Pig',
        'Fish',
        'Pet Livestock',
        'Pet Poultry',
    ];

    const Pets = (props) => (
        <div>
            <div className={foster.overallPetPost}>
                <Link className={foster.petPost} to={`/view-foster-listing/${props.pet.listingID}`}>
                    {props.pet.picture[0] ? (
                        <img
                            src={props.pet.picture[0]}
                            className={foster.petPicture}
                            alt="Pet Image"
                        />
                    ) : (
                        <img
                            src= "/Image_not_available.png"
                            alt="Image Not Available"
                            className={foster.petPicture}
                        />
                    )}
                    <div className={foster.nameAndFeeTextContainer}>
                        <div className={foster.signintext}>
                            {props.pet.name}
                        </div>
                        <div className={foster.gridColoring}>
                            {props.pet.fee !== null ? 
                                <div>
                                    Fee: ${props.pet.fee}
                                </div> : 
                                <div className={foster.feeText}>
                                    Message For Price
                                </div>}
                        </div>
                    </div>
                    <div className={foster.locationText}>
                        <div className={foster.tworow}>
                            <div>
                                Posted On: {new Date(props.pet.datePosted).toLocaleDateString()}
                            </div>
                            <div>
                                Last Updated: {new Date(props.pet.dateUpdated).toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );


    const handleChange = (field, value) => {
        setFilterData((prev) => ({...prev, [field]: value}));
        if (value === types[0] || value === sortedTypes[0]) {
            setFilterData((prev) => ({...prev, [field]: ""}));
        }

    };

    const handleReset = async () => {
        window.location.reload();
    }

    const handleRangeChange = (field, value, index) => {
        setFilterData((prev) => {
            if (field === 'dateUpdated' || field === 'datePosted') {
                value = new Date(value);
            }
            const updatedRange = [...prev[field]];
            updatedRange[index] = value;
            return { ...prev, [field]: updatedRange };
        });
    };

    const handleFormClick = async () => {
        setFilterData((prev) => ({...prev, 'username': filterData['search']}));
        var tempData = filterData;
        if (filterData.sort !== ""){
            if (filterData.sort === 'Ascending')
            {
                tempData.sort = 1;
            }
            if (filterData.sort === 'Descending')
            {
                tempData.sort = -1;
            }
        }
        if (filterData.sortedField !== "")
        {
            if (filterData.sortedField === 'Date Updated')
            {
                tempData.sortedField = "dateUpdated";
            }
            if (filterData.sortedField === 'Date Posted')
            {
                tempData.sortedField = "datePosted";
            }
        }
        setDataUsed(true);
        const result = await getAllActiveFosterListings(tempData);

        setFosterListings(result);
        setDataUsed(false);
    }

    function fosterOrNot() {
        if (fosterListings.length === 0) {
            return (
                <div className={foster.noListingsText}>
                    No Foster Listings
                </div>
            );
        }
        else {
            return (
                fosterList()
            );
        }
    }

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {
                const userDetails = await getAccountDetails(res.user.userID);
                setUserData(userDetails);
                setImagePreview(userDetails.profilePicture);
                const petDetails = await getAllActiveFosterListings();
                // console.log(petDetails);
                setFosterListings(petDetails);
            }
        };

        checkToken();
    }, [setUser, navigate, setFosterListings]);


    function fosterList() {
        return fosterListings.map((pet) => {
            console.log(pet)
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    return (
        <div className={foster.page}>
            <div className={foster.containerForFilters}>
                <div className={foster.innerFiltersContainer}>
                    <TextField
                        id="standard-basic"
                        label="Search"
                        variant="standard"
                        size="small"
                        className={foster.filterTextField}
                        onChange={(e) => handleChange("search", e.target.value)}
                    />

                    <div className={foster.filterContent}>
                        Filter By Type:
                        <div className={foster.spacing}>
                            <TextField
                                select
                                size="small"
                                variant="outlined"
                                fullWidth
                                value={filterData.type}
                                onChange={(e) => handleChange("type", e.target.value)}
                                label="Type of Pet"
                                disabled={dataUsed}
                            >
                                {types.map((type) => (
                                    <MenuItem
                                        key={type}
                                        value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>

                    <div className={foster.filterContent}>
                        Filter By Age:
                        <div className={foster.twocolumn}>
                            <TextField
                                size="small"
                                label="Youngest Age"
                                variant="outlined"
                                type="number"
                                onChange={(e) => handleRangeChange("age", e.target.value, 0)}
                            />
                            <TextField
                                size="small"
                                label="Oldest Age"
                                variant="outlined"
                                type="number"
                                onChange={(e) => handleRangeChange("age", e.target.value, 1)}
                            />
                        </div>
                    </div>

                    <div className={foster.filterContent}>
                        Filter by Date Posted:
                        <div className={foster.twocolumn}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    views={['year', 'month', 'day']}
                                    onChange={(date) => handleRangeChange("datePosted", date, 0)}
                                    disabled={dataUsed}
                                    slotProps={{textField: {size: 'small'}}}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    views={['year', 'month', 'day']}
                                    onChange={(date) => handleRangeChange("datePosted", date, 1)}
                                    disabled={dataUsed}
                                    slotProps={{textField: {size: 'small'}}}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className={foster.filterContent}>
                        Filter By Date Updated:
                        <div className={foster.twocolumn}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    views={['year', 'month', 'day']}
                                    onChange={(date) => handleRangeChange("dateUpdated", date, 0)}
                                    disabled={dataUsed}
                                    slotProps={{textField: {size: 'small'}}}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    views={['year', 'month', 'day']}
                                    onChange={(date) => handleRangeChange("dateUpdated", date, 1)}
                                    disabled={dataUsed}
                                    slotProps={{textField: {size: 'small'}}}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className={foster.filterContent}>
                        <div>
                            Order By:
                            <Select
                                size="small"
                                value={filterData.sortedField}
                                onChange={(e) => handleChange("sortedField", e.target.value)}
                                fullWidth
                                label="Order By"
                                disabled={dataUsed}
                            >
                                {sortedTypes.map((sortedTypes) => (
                                    <MenuItem
                                        key={sortedTypes}
                                        value={sortedTypes}
                                    >
                                        {sortedTypes}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {(filterData.sortedField !== "") &&
                        <div className={foster.filterContent}>
                            <div>
                                Order By:
                                <Select
                                    size="small"
                                    value={filterData.sort}
                                    onChange={(e) => handleChange("sort", e.target.value)}
                                    fullWidth
                                    label="Order By"
                                    disabled={dataUsed}
                                >
                                    {orderTypes.map((orderTypes) => (
                                        <MenuItem
                                            key={orderTypes}
                                            value={orderTypes}
                                        >
                                            {orderTypes}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>}

                    <div className={foster.filterButtonSearch}>
                        <div></div>
                        <Button variant="contained" onClick={handleFormClick}>
                            Search
                        </Button>
                        <Button variant="contained" onClick={handleReset}>
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
            <div className={foster.welcomebacktext}>
                Pets up for Fostering
            </div>

            <div className={foster.listingsContainer}>


                <div className={foster.petListings}>
                    <ul className={foster.listOfPets}>
                        {fosterOrNot()}
                    </ul>
                </div>
            </div>
        </div>
    );
}