import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getAdoptFosterListing} from "../../api/adoptFosterApi";
import {getAccountDetails} from "../../api/userApi";
import css from "./ViewAdoptListing.module.css";
import {Button, Card, CardMedia} from '@mui/material';
import {useUser} from "../../context/UserContext";
import {refreshToken} from "../../api/authApi";
import {GoogleMapsDisplay, GeocodePlaceId} from "../../component/GoogleMaps/GoogleMapsDisplay";

export default function ViewAdoptListing() {
    const navigate = useNavigate();
    const [pet, setPet] = useState(null);
    const [arrayOfPetPic, setArrayOfPetPic] = useState(null);
    const {id} = useParams();
    const [number, setNumber] = useState(0);
    const [userName, setUserName] = useState(null);
    const { user, setUser } = useUser();
    const previous = "<";
    const next = ">"
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

    useEffect(() => {
        async function fetchData() {
            if (id != null){
                const response = await getAdoptFosterListing(id);
                if (response.error !== "Listing Not Found") {
                    /*Updating the dates */
                    response.datePosted = new Date(response.datePosted).toLocaleDateString(undefined, options);
                    response.dateUpdated = new Date(response.dateUpdated).toLocaleDateString(undefined, options);
                    response.dob = new Date(response.dob).toLocaleDateString();
                    
                    /*Ensuring everything is set at N/A if blank */
                    for (const value in response) {
                        if (response[value] === "") {
                            response[value] = "N/A";
                        }
                    }
                    /*Set everything into response */
                    setPet(response);
                    setArrayOfPetPic(response.picture);
                    const accountDetail = await getAccountDetails(response.userID);
                    const res = await refreshToken();
                    setUser(res.user);
                    if (!res.user) {
                        navigate("../../login");
                    }
                    if (accountDetail != null){
                        setUserName(accountDetail.name);
                    }
                }
            }
        }

        fetchData();

    }, [id, setUser, navigate]);

    if (!pet || !pet.name) {
        return  <div className={css.pageNotFound}>Page Not Found</div>;
    }

    const leftClick = async () => {
        if (arrayOfPetPic != null)
        {
            if (number - 1 < 0) {
                setNumber(arrayOfPetPic.length - 1);
            }
            else{
                setNumber(number-1);
            }
        }
    };

    const rightClick = async () => {
        if (arrayOfPetPic != null) {
            if (number  + 1 >= arrayOfPetPic.length) {
                setNumber(0);
            }
            else{
                setNumber(number + 1);
            }
        }

    };

    const handleMessageOwner = () => {
        navigate(`/messages/${user.userID}`)
    }

    const handleEditListing = () => {
        navigate(`/update-listing/${id}`)
    }

    const handleReportListing = () => {
        navigate(`/report-pet-listing/${id}`)
    }

    const {
        gender,
        description,
        name,
        type,
        breed,
        age,
        dob,
        weight,
        picture,
        specialNeeds,
        datePosted,
        dateUpdated,
        status,
        adoptOrFoster,
        pickupLocationID,
        organizationID,
        fee,
    } = pet;


    const statusText = status === 1 ? 'Active' : status === 0 ? 'Inactive' : 'Deleted';
    const adoptOrFosterText = adoptOrFoster === 0 ? 'Adopt' : 'Foster';

    return (
      <div className={css.page}>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
        </style>
        <div className={css.containerForInformation}>
            <div className={css.twocolumncontainer}>
                <div>
                    <div className={css.imageBox}>
                        {(arrayOfPetPic != null && arrayOfPetPic.length > 1) &&
                            <Card sx={{maxWidth: '100%', maxHeight: '100%'}}>
                                <CardMedia
                                    component="img"
                                    image={arrayOfPetPic[number]}
                                    alt={`${name}`}/>
                            </Card>}
                        {(arrayOfPetPic != null && arrayOfPetPic.length === 1) &&
                            <Card sx={{maxWidth: '85%', maxHeight: '85%'}}>
                                <CardMedia
                                    component="img"
                                    image={arrayOfPetPic[number]}
                                    alt={`${name}`}/>
                            </Card>}
                        {(arrayOfPetPic == null || arrayOfPetPic.length === 0) &&
                            <Card sx={{maxWidth: '85%', maxHeight: '85%'}}>
                                <CardMedia
                                    component="img"
                                    src="/Image_not_available.png"
                                    alt={`Image Not Available`}/>
                            </Card>}
                    </div>
                    {(arrayOfPetPic != null && arrayOfPetPic.length > 1) &&
                        <div className={css.buttonAlign}>
                            <Button style={{backgroundColor: "#bee3db"}} color="#555B6E" size="large"
                                    onClick={leftClick}>
                                {previous}
                            </Button>
                            {number + 1} / {arrayOfPetPic.length}
                            <Button style={{backgroundColor: "#bee3db"}} color="#555B6E" size="large"
                                    onClick={rightClick}>
                                {next}
                            </Button>
                        </div>
                    }
                    <div className={css.tworow}>
                        <div className={css.editListingButton}>
                            {pet.userID === user.userID ?
                                <Button variant="contained"
                                        sx={{
                                            backgroundColor: '#89B0AE'
                                        }}
                                        onClick={handleEditListing}
                                >
                                    Edit Pet Information
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    className={css.messageButton}
                                    sx={{
                                        backgroundColor: '#89B0AE'
                                    }}
                                    onClick={handleMessageOwner}
                                >
                                    Message Owner
                                </Button>}
                        </div>
                        <div className={css.buttonSubmit}>
                            <Button style={{backgroundColor: "#555B6E"}} variant="contained" onClick={handleReportListing}>Report Pet Listing</Button>
                        </div>
                    </div>
                </div>
                <div className={css.containerForText}>

                    <div className={css.description}>
                        {pet.name}

                    </div>

                    <div className={css.descriptionBox}>
                        <div className={css.descriptionInformation}>
                            Description: {pet.description}
                        </div>
                        <div className={css.descriptionInformation}>
                            Type: {pet.type}
                        </div>
                        <div className={css.descriptionInformation}>
                            Breed: {pet.breed}
                        </div>
                        <div className={css.descriptionInformation}>
                            Gender: {pet.gender}
                        </div>
                        <div className={css.descriptionInformation}>
                            Date of Birth: {pet.dob}
                        </div>
                        <div className={css.descriptionInformation}>
                            Age: {pet.age} years old
                        </div>
                        <div className={css.descriptionInformation}>
                            Weight: {pet.weight} lb
                        </div>
                        <div className={css.descriptionInformation}>
                            Special Needs: {pet.specialNeeds}
                        </div>
                        {/* {status === 0 ? */}
                            <div className={css.descriptionInformation}>
                             Status: { adoptOrFosterText}
                                {/* {adoptOrFoster === 1 ?
                                        "Adopted" : "Fostered"
                                } */}
                            </div>
                            {/* : ""} */}
                        {(pet.organizationID != null) && <div className={css.descriptionInformation}>
                            Organization: {pet.organizationID}
                        </div>}
                        <div className={css.descriptionInformation}>
                            <GeocodePlaceId placeId={pet.pickupLocationID}/>
                        </div>
                        <div className="google-map">
                            <GoogleMapsDisplay placeID={pet.pickupLocationID}/>
                        </div>
                    </div>

                </div>
            </div>
            <div className={css.formatOfDates}>
                {(userName != null) && <div className={css.dateUpdated}>
                    Posted By: {userName}
                </div>}
                <div className={css.dateUpdated}>
                    Posted On: {pet.datePosted}
                </div>
                <div className={css.dateUpdated}>
                    Last Updated: {pet.dateUpdated}
                </div>
            </div>
            <div className={css.footer}>
            </div>
        </div>
      </div>
    );
}