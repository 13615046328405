import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions, Select, MenuItem, OutlinedInput, InputLabel,
} from "@mui/material";
import {
  createAdoptionListing,
  createFosterListing,
  createFoundListing,
  createLostListing,
  createRehomeListing
} from "../../api/createListingApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Delete } from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { refreshToken } from "../../api/authApi";
import { useUser } from "../../context/UserContext";
import { GooglePlacesAutocompleteAdd } from "../../component/GoogleMaps/GooglePlacesAutocomplete";
import { enhanceText } from "../../component/ChatGPT/ChatGPTHelpers";
import createListingStyles from "./CreateListing.module.css";

export default function CreateListingPage() {
  const [listingType, setListingType] = useState("adoption");
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    type: "",
    description: "",
    dob: dayjs(),
    fee: "",
    weight: "",
    breed: "",
    locationID: "",
    specialNeeds: "",
    adoptOrFoster: "",
    pickupDate: dayjs(),
    foundDate: dayjs(),
    lastSeenDate: dayjs(),
    chipNumber: "",
    foodReq: ""
  });
  const requiredFields = {
    adoption: ["name", "type", "age", "weight", "gender", "description"],
    foster: ["name", "type", "age", "weight", "gender", "description"],
    found: ["type", "description", "locationID", "foundDate"],
    lost: ["name", "type", "age", "weight", "gender", "description", "locationID", "lastSeenDate", "chipNumber"],
    rehome: ["name", "type", "age", "weight", "gender", "pickupDate"]
  };
  const types = [
    'Cat',
    'Dog',
    'Hamster',
    'Ferret',
    'Bird',
    'Reptile',
    'Amphibian',
    'Rabbit',
    'Guinea Pig',
    'Fish',
    'Pet Livestock',
    'Pet Poultry',
  ];
  const [dataUsed, setDataUsed] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const today = dayjs();
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleEnhanceDescClick = async() => {
    if (!formData.description || formData.description === "") {
      setSnackbarMessage("Description must be provided.");
      setOpenSnackbar(true);
    } else {
      const enhanced = await enhanceText(formData.description);
      handleChange("description", enhanced);
    }
  }

  const vertical = "bottom";
  const horizontal = "center";

  const handleFormClick = async () => {
    if (!validateForm()) return;

    const finalFormData = {
      ...formData,
      userID: user.userID,
      dob: formData.dob?.toISOString(),
      pickupDate: formData.pickupDate?.toISOString(),
      lastSeenDate: formData.lastSeenDate?.toISOString(),
      foundDate: formData.foundDate?.toISOString()
    };

    setDataUsed(true);

    let result;
    try {
      switch (listingType) {
        case "adoption":
          result = await createAdoptionListing(finalFormData, selectedFiles);
          break;
        case "foster":
          result = await createFosterListing(finalFormData, selectedFiles);
          break;
        case "found":
          result = await createFoundListing(finalFormData, selectedFiles);
          break;
        case "lost":
          result = await createLostListing(finalFormData, selectedFiles);
          break;
        case "rehome":
          result = await createRehomeListing(finalFormData, selectedFiles);
          break;
        default:
          return;
      }

      if (result.status >= 400 && result.status < 500) {  // Check for 400-level status codes

        result = await result.json();
        setSnackbarMessage(`Error: ${result.message || "Something went wrong."}`);
        setOpenSnackbar(true);
      } else if (result.ok) {
        navigate("../../home");
      }
    } catch (error) {
      setSnackbarMessage("An unexpected error occurred.");
      setOpenSnackbar(true);
    } finally {
      setDataUsed(false);
    }
  };

  const validateForm = () => {

    const valid = requiredFields[listingType].every((field) => formData[field] !== "");
    if (!valid) {
      // snack bar message
      setSnackbarMessage(`Missing fields: ${requiredFields[listingType].filter((field) => formData[field] === "").join(", ")}`);
      setOpenSnackbar(true);
    }
    return valid
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  const handleRemoveImage = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const checkToken = async () => {
      const res = await refreshToken();
      setUser(res.user);
      if (!res.user) navigate("../../login");
    };
    checkToken();
  }, [setUser, navigate]);

  const handleCloseAlert = () => {
    setOpenSnackbar(false);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleConfirmDialog = () => {
    setOpenConfirmDialog(true);
  }
  const handleSubmit = () => {
    if (!validateForm()) return;
    handleConfirmDialog();
  }

  return (
    <div className={createListingStyles.page}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackbar}
        onClose={handleCloseAlert}
        message={snackbarMessage}
        key={vertical + horizontal}
      />
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
      </style>
      <div className={createListingStyles.containerWithItems}>
        <div className={createListingStyles.containerwithtext}>
          <div className={createListingStyles.title}>Create a New Listing</div>
          <FormControl>
            <FormLabel>Type of Listing:</FormLabel>
            <RadioGroup
              row
              value={listingType}
              onChange={(e) => setListingType(e.target.value)}
            >
              <FormControlLabel value="adoption" control={<Radio />} label="Adoption" />
              <FormControlLabel value="foster" control={<Radio />} label="Foster" />
              <FormControlLabel value="found" control={<Radio />} label="Found" />
              <FormControlLabel value="lost" control={<Radio />} label="Lost" />
              <FormControlLabel value="rehome" control={<Radio />} label="Rehome" />
            </RadioGroup>
          </FormControl>

          <div className={createListingStyles.containerwithitemfill}>
            <TextField label="Name" fullWidth onChange={(e) => handleChange("name", e.target.value)} disabled={dataUsed}
                       required/>
            <TextField label="Age" fullWidth onChange={(e) => handleChange("age", e.target.value)} disabled={dataUsed}
                       required={['adoption', 'foster', 'lost', 'rehome'].includes(listingType)}/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                  label="Birthday"
                  views={['year', 'month', 'day']}
                  maxDate={today}
                  value={formData.dob}
                  onChange={(date) => handleChange("dob", date)}
                  disabled={dataUsed}
              />
            </LocalizationProvider>
            <TextField label="Gender" fullWidth onChange={(e) => handleChange("gender", e.target.value)}
                       disabled={dataUsed} required/>
            <TextField label="Weight" placeholder="in pounds (lbs)" fullWidth
                       onChange={(e) => handleChange("weight", e.target.value)} disabled={dataUsed} required/>
            <FormControl sx={{m: 0}}>
              <InputLabel id="demo-simple-select-autowidth-label">Type of Pet *</InputLabel>
              <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={formData.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                  autoWidth
                  label="Type of Pet"
                  required={true}
                  disabled={dataUsed}
              >
                {types.map((type) => (
                    <MenuItem
                        key={type}
                        value={type}
                    >
                      {type}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField label="Breed" fullWidth onChange={(e) => handleChange("breed", e.target.value)}
                       disabled={dataUsed}/>
            <TextField label="Special Needs" fullWidth onChange={(e) => handleChange("specialNeeds", e.target.value)}
                       disabled={dataUsed}/>

            <TextField
                fullWidth
                disabled={dataUsed}
                InputProps={{
                  inputComponent: GooglePlacesAutocompleteAdd,
                  inputProps: {
                    onPlaceSelected: (place) => handleChange("locationID", place.place_id),
                  },
                }}
            />

            {/* Additional Fields */}

            {listingType === 'rehome' && (
                <TextField label="Food Requirements" fullWidth onChange={(e) => handleChange("foodReq", e.target.value)}
                           disabled={dataUsed}/>
            )}


            {['adoption', 'foster'].includes(listingType) && (
                <TextField label="Fee" fullWidth onChange={(e) => handleChange("fee", e.target.value)}
                           disabled={dataUsed}/>
            )}

            {['found', 'lost'].includes(listingType) && (
                <TextField label="Chip Number" fullWidth onChange={(e) => handleChange("chipNumber", e.target.value)}
                           disabled={dataUsed} required={listingType === 'lost'}/>
            )}


            {listingType === 'found' && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      label="Found Date"
                      views={['year', 'month', 'day']}
                      value={formData.foundDate}
                      onChange={(date) => handleChange("foundDate", date)}
                      disabled={dataUsed}
                  />
                </LocalizationProvider>
            )}

            {listingType === 'lost' && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      label="Last Seen Date"
                      views={['year', 'month', 'day']}
                      value={formData.lastSeenDate}
                      onChange={(date) => handleChange("lastSeenDate", date)}
                      disabled={dataUsed}
                  />
                </LocalizationProvider>
            )}

            {listingType === 'rehome' && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                      label="Pickup Date"
                      views={['year', 'month', 'day']}
                      value={formData.pickupDate}
                      onChange={(date) => handleChange("pickupDate", date)}
                      disabled={dataUsed}
                  />
                </LocalizationProvider>
            )}
            <TextField
                label="Description"
                placeholder=""
                fullWidth
                onChange={(e) => handleChange("description", e.target.value)}
                disabled={dataUsed}
                multiline
                rows={5}
                required={(listingType === 'adoption' || listingType === 'foster' || listingType === 'lost' || listingType === 'found')}
                value={formData.description}
            />
            {/* Upload Section */}
            <div className={createListingStyles.buttonEnhanceContainer}>
              <Button
                  component="label"
                  variant="contained"
                  disabled={dataUsed}
                  >
                Upload Images
                <input type="file" ref={fileInputRef} hidden multiple accept="image/*" onChange={handleFileChange}/>
              </Button>

              <Button
                  onClick={handleEnhanceDescClick}
                  variant="contained"
                  disabled={dataUsed}
                  className="enhanceButton"
                  sx={{
                    backgroundColor: '#89B0AE'
                  }}
              >
                Enhance your description with AI
              </Button>
            </div>
            <div className={createListingStyles.uploadSection}>
              {selectedFiles.length > 0 && (
                  <Carousel showThumbs={false} showStatus={false} className={createListingStyles.carouselContainer}>
                    {selectedFiles.map((file, index) => (
                        <div key={index} className={createListingStyles.carouselImageContainer}>
                          <img src={URL.createObjectURL(file)} alt={`Preview ${index}`}
                               className={createListingStyles.carouselImage}/>
                          <IconButton onClick={() => handleRemoveImage(index)}
                                      style={{position: 'absolute', top: '10px', right: '10px', color: 'red'}}>
                            <Delete/>
                          </IconButton>
                        </div>
                    ))}
                  </Carousel>
              )}

            </div>
          </div>

          {/* Submit Button */}
          <div className={createListingStyles.buttonSubmit}>
            <Button style={{backgroundColor: "#555B6E"}} variant="contained" onClick={handleSubmit}>SUBMIT</Button>
          </div>

          {/* Submit Confirmation */}
          <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
            <DialogTitle>You will create a posting for {formData.name ? formData.name : "None"}</DialogTitle>
            <DialogActions>
              <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
              <Button onClick={handleFormClick} autoFocus>Confirm</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
