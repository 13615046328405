import React, {useEffect, useState} from "react";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

const libraries = ['places'];

export function GeocodePlaceId({ placeId }) {
    const [address, setAddress] = useState("Loading...");

    useEffect(() => {
        if (!placeId) {
            return;
        }
        if (placeId === "" || placeId === "N/A") {
            setAddress("No Address Provided");
            return;
        }
        const geocoder = new window.google.maps.Geocoder();
        geocoder
            .geocode({placeId: placeId},)
            .then(({ results }) => {
                if (results[0]) {
                    setAddress(results[0].formatted_address);
                } else {
                    setAddress("No results found")
                }
            })
            .catch((e) => {
                window.alert("Geocoder failed due to: " + e)
                setAddress(e);
            });
    }, [placeId]);

    return <div>
        Location: {address}
    </div>
}

export const GoogleMapsDisplay = ({placeID}) => {
    const mapContainerStyle = {
        width: '40vw',
        height: '30vh',
    };
    const defaultCenter = {
        // Stewart Welcome Center
        lat: 40.4250578, // default latitude
        lng: -86.9127431, // default longitude
    };
    const [center, setCenter] = useState(defaultCenter);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyC94VAcY8k2icBoRPj4WrPirJNr332lq_A',
        libraries,
    });

    useEffect(() => {
        if (isLoaded && placeID && placeID !== "" && placeID !== "N/A") {
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails({ placeId: placeID }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setCenter({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    });
                }
            });
        }
    }, [isLoaded, placeID]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    return (placeID !== "N/A" && placeID !== "") ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={20}
            center={center}
        >
            <Marker position={center} />
        </GoogleMap>
    ) : (
        <div></div>
    );
};