import lostPets from "./LostPetsHomepage.module.css";
import {getAllLostListings, getAllActiveListings} from "../../api/lostApi";
import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import {refreshToken} from "../../api/authApi";
import {useUser} from "../../context/UserContext";
import {GeocodePlaceId} from "../../component/GoogleMaps/GoogleMapsDisplay";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    CardMedia,
    TextField,
    Button, Select, MenuItem,
  } from "@mui/material";


export default function LostPetsHomepage() {
    const [dataUsed, setDataUsed] = useState(false);
    const [petListings, setPetListings] = useState("");
    const [petImage, setPetImage] = useState(null);
    const navigate = useNavigate();
    const { user, setUser } = useUser();

    const types = [
        'All',
        'Cat',
        'Dog',
        'Hamster',
        'Ferret',
        'Bird',
        'Reptile',
        'Amphibian',
        'Rabbit',
        'Guinea Pig',
        'Fish',
        'Pet Livestock',
        'Pet Poultry',
      ];

      const orderTypes = [
        'Ascending',
        'Descending'
      ];

      const sortedTypes = [
        'None',
        'Post Updated', 
        'Post Posted'
      ];

      const [filterData, setFilterData] = useState({
        search: "",
        username: "",
        type: "",
        datePosted:["",""],
        dateUpdated:["",""],
        age:[,],
        sortedField: "",
        sort:""
      });

      const handleChange = (field, value) => {
        setFilterData((prev) => ({ ...prev, [field]: value }));
        if (value == types[0] || value == sortedTypes[0]){
            setFilterData((prev) => ({ ...prev, [field]: ""}));
        }
        
      };

      const handleRangeChange = (field, value, index) => {
        setFilterData((prev) => {
            if (field == 'dateUpdated' || field == 'datePosted') {
                value = new Date(value);
            }
            const updatedRange = [...prev[field]];
            updatedRange[index] = value;
            return { ...prev, [field]: updatedRange };
        });
    };

      const handleFormClick = async () => {
        setFilterData((prev) => ({...prev, 'username': filterData['search']}));
        var tempData = filterData;
        if (filterData.sort !== ""){
            if (filterData.sort === 'Ascending')
                {
                    tempData.sort = 1;
                }
            if (filterData.sort === 'Descending')
                {
                    tempData.sort = -1;
                }
        }
        if (filterData.sortedField !== "")
        {
            if (filterData.sortedField === 'Post Updated')
                {
                    tempData.sortedField = "dateUpdated";
                }
            if (filterData.sortedField === 'Post Posted')
                {
                    tempData.sortedField = "datePosted";
                }
        }
        setDataUsed(true);
        const result = await getAllActiveListings(tempData);

        //TODO - NEED TO ADD ERROR MESSAGES IF BACKEND HAS ERRORS 
        setPetListings(result);
        setDataUsed(false);
      }
    
      const handleReset = async () => {
        window.location.reload();
      }

    const Posts = (props, key) => (
        <div className={lostPets.post}>
            <Button class={lostPets.button} href={`/view-lost-found-post/${props.pet.listingID}`}>
            <div className={lostPets.postedby}>
                <div className={lostPets.userposter}>
                    <Button href={`/view-user-profile/${props.pet.userID}`}>{props.pet.username}</Button>
                </div>
                <div className={lostPets.dateposted}>
                    <div>
                        Posted On: {new Date(props.pet.datePosted).toLocaleDateString()}
                    </div>
                    <div>
                        Last Updated On: {new Date(props.pet.dateUpdated).toLocaleDateString()}
                    </div>
                </div>
            </div>
            <div className={lostPets.post}>
                <div className={lostPets.postdescription}>  
                    <div className={lostPets.words}>
                         <div className={lostPets.name}>
                            {props.pet.name} -  {props.pet.gender} {props.pet.type}
                        </div>
                        <div className={lostPets.information}>
                            Last Seen on {new Date(props.pet.lastSeenDate).toLocaleDateString()} 
                        </div>
                        <div className={lostPets.information}>
                            {props.pet.lastSeenLocationID && props.pet.lastSeenLocationID !== "" && props.pet.lastSeenLocationID !== "N/A"
                                        ? <GeocodePlaceId placeId={props.pet.lastSeenLocationID} />
                                        : "Message for location"}
                        </div>
                        <div className={lostPets.information}>
                            {props.pet.description}
                        </div>
                    </div>
                    <div className={lostPets.buttonPlacement}>
                        <Button variant="contained" style={{ backgroundColor: "#555B6E" }} size="large" href={`/messages/${props.pet.userID}`}>
                            Found it! 
                        </Button>
                    </div>
                        
                </div>
                    <div className={lostPets.image}>
                    {(props.pet.picture.length !== 0) &&
                                <CardMedia
                                    sx={{ maxWidth: '200px', maxHeight: "150px", minHeight:"150px"}}
                                    component="img"
                                    image={props.pet.picture[0]}
                                    alt={`${props.pet.name}`}/>
                    }
                    {(props.pet.picture.length === 0) &&
                        <CardMedia
                            sx={{ maxWidth: '200px', maxHeight: '200px', minHeight:"150px"}}
                            component="img"
                            src= "Image_not_available.png"
                            alt={`Image Not Available`}/>
                    }
                    </div>
                </div>
            </Button>
        </div>
    );

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {

                const result = await getAllActiveListings();
                setPetListings(result);
            }
        };

        checkToken();
    }, [setUser, navigate, setPetListings]);


    function listOfPetsLost() {
        if (petListings.length === 0) {
            return (
                <div className={lostPets.page}>
                    <div className={lostPets.nonexist}>
                    No Lost Pet Posting at the moment.
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className={lostPets.listOfPets}>
                    {petList()}
                </div>
            );
        }
    }

    
    function petList() {
        return petListings.map((pet) => {
            return (
                <Posts
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }


    return (
        <div className={lostPets.page}>
            <div className={lostPets.welcomebacktext}>
                Lost Pet Postings
            </div>
            <div className={lostPets.containerWithItems}>
                <div className={lostPets.filterTwoColumn}>
                    <div className={lostPets.filterBox}>
                        <div className={lostPets.filterItem}>
                            {/* <div className={lostPets.filterContentTitle}>
                                Filters
                            </div> */}
                            <div className={lostPets.filterContent}>
                                <TextField  label="Search" variant="standard" fullWidth size="small" 
                                onChange={(e) => handleChange("search", e.target.value)}/>
                            </div>
                            <div className={lostPets.filterContent}>
                                <div>
                                    Type:
                                    <div className={lostPets.spacing}>
                                        <Select
                                        size="small"
                                                value={filterData.type}
                                                onChange={(e) => handleChange("type", e.target.value)}
                                                fullWidth
                                                label="Type of Pet"
                                                disabled={dataUsed}
                                            >
                                                {types.map((type) => (
                                                    <MenuItem
                                                        key={type}
                                                        value={type}
                                                    >
                                                    {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                    </div>
                                        
                                </div>

                            </div>
                            <div className={lostPets.filterContent}>
                                Age:
                                <div className={lostPets.twocolumn}>
                                    <TextField
                                        size="small"
                                        label="Youngest Age"
                                        type="number"
                                        onChange={(e) => handleRangeChange("age", e.target.value,  0)}
                                        />
                                    <TextField
                                        size="small"
                                        label="Oldest Age"
                                        type="number"
                                        onChange={(e) => handleRangeChange("age", e.target.value,  1)}
                                        />
                                </div>
                            </div>
                            <div className={lostPets.filterContent}>
                                Dated Posted:
                                <div className={lostPets.twocolumn}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Start Date"
                                            views={['year', 'month', 'day']}
                                            onChange={(date) => handleRangeChange("datePosted", date,  0)}
                                            disabled={dataUsed}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="End Date"
                                            views={['year', 'month', 'day']}
                                            onChange={(date) => handleRangeChange("datePosted", date,  1)}
                                            disabled={dataUsed}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className={lostPets.filterContent}>
                                Dated Updated:
                                <div className={lostPets.twocolumn}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Start Date"
                                            views={['year', 'month', 'day']}
                                            onChange={(date) => handleRangeChange("dateUpdated", date,  0)}
                                            disabled={dataUsed}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            
                                            label="End Date"
                                            views={['year', 'month', 'day']}
                                            onChange={(date) => handleRangeChange("dateUpdated", date,  1)}
                                            disabled={dataUsed}
                                            slotProps={{ textField: { size: 'small' } }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className={lostPets.filterContent}>
                                <div>
                                    Sorted By:
                                        <Select
                                            size="small"
                                            value={filterData.sortedField}
                                            onChange={(e) => handleChange("sortedField", e.target.value)}
                                            fullWidth
                                            label="Sorted By"
                                            disabled={dataUsed}
                                        >
                                            {sortedTypes.map((sortedTypes) => (
                                                <MenuItem
                                                    key={sortedTypes}
                                                    value={sortedTypes}
                                                >
                                                {sortedTypes}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                </div>
                            </div>
                            {(filterData.sortedField!= "") && 
                                <div className={lostPets.filterContent}>
                                    <div>
                                        Order By:
                                            <Select
                                                size="small"
                                                value={filterData.sort}
                                                onChange={(e) => handleChange("sort", e.target.value)}
                                                fullWidth
                                                label="Order By"
                                                disabled={dataUsed}
                                            >
                                                {orderTypes.map((orderTypes) => (
                                                    <MenuItem
                                                        key={orderTypes}
                                                        value={orderTypes}
                                                    >
                                                    {orderTypes}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                    </div>
                                </div>}
                            <div className ={lostPets.filterButtonSearch}>
                                <div>

                                </div>
                                <Button variant="contained" size="small" onClick={handleFormClick}>
                                    Search
                                </Button>
                                <Button variant="contained" size="small"  onClick={handleReset}>
                                    Reset
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={lostPets.listOfPosts}>
                    {listOfPetsLost()}
                    </div>
                </div>
            </div>
        </div>
    );
}