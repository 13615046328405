import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Stack,
    Avatar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Alert
} from "@mui/material";
import viewUser from "./ViewUserProfile.module.css";
import { useUser } from "../../context/UserContext";
import {logoutUser, refreshToken} from "../../api/authApi";
import {
    getAccountDetails,
    updateProfilePicture,
    getUserAdoptFosterListings,
    getUserRehomeListings,
    getUserLostListings,
    getUserFoundListings,
    getUserActiveListings
} from "../../api/userApi";
import {Link, useNavigate, useParams} from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import currentListings from "../CurrentListings/CurrentListings.module.css";

export default function ViewUserProfilePage() {
    const { user, setUser } = useUser();
    const {id} = useParams();
    const [userData, setUserData] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // For confirmation dialog    
    const navigate = useNavigate();
    const [currentListings, setCurrentListings] = useState("");

    useEffect(() => {
        const checkToken = async () => {
            if (user === undefined) {
                navigate("../../login");
            } else if (!id) {
                navigate("/");
            } else if (user !== null) {
                if (id) {
                    const userDetails = await getAccountDetails(id);
                    setUserData(userDetails);
                    console.log(userDetails);
                    setImagePreview(userDetails.profilePicture);

                    const userListings = await getUserActiveListings(userDetails.userID);
                    setCurrentListings(userListings)
                    console.log(userListings);
                }
            }
        };

        checkToken();
    }, [user, navigate]);

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            document.getElementById("fileInput").click();
        }
    };

    const handleCurrentListingClick = async () => {
        navigate(`../messages/${userData?.userID}`);
    };

    const handleGoHome = async () => {
        navigate(`/`);
    };

    const [state, setState] = useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });

    const { vertical, horizontal, open } = state;

    const handleCloseAlert = () => {
        setState({ ...state, open: false });
    };

    function navigateListing(props) {
        if (props.pet.listingType === "AdoptFosterListing") {
            /*
             * Navigate to adopt listing if adoptOrFoster is 0.
             * Navigate to foster listing if adoptOrFoster is 1.
             */
            if (props.pet.adoptOrFoster === 0) {
                return `/viewadoptlisting/${props.pet.listingID}`;
            }
            else {
                return `/view-foster-listing/${props.pet.listingID}`;
            }
        }
        else if (props.pet.listingType === "RehomeListing") {
            return `/viewrehomelisting/${props.pet.listingID}`;
        }
        else if (props.pet.listingType === "LostListing") {
            return `/view-lost-found-post/${props.pet.listingID}`;
        }
        else if (props.pet.listingType === "FoundListing") {
            return `/view-lost-found-post/${props.pet.listingID}`;
        }
    }

    const Pets = (props) => (
        <div>
            <div className={viewUser.overallPetPost}>
                <Link className={viewUser.petPost} to={navigateListing(props)}>
                    <div className={viewUser.petImage}>
                        {props.pet.picture[0] ? (
                            <img
                                src={props.pet.picture[0]}
                                className={viewUser.petPicture}
                                alt="Pet Image"
                            />
                        ) : (
                            <img
                                src= "/Image_not_available.png"
                                alt="Image Not Available"
                                className={viewUser.petPicture}
                            />
                        )}
                    </div>
                    <div className={viewUser.containerPetPostText}>
                        {props.pet.name}
                    </div>
                </Link>
            </div>
        </div>
    );

    function getCurrentListings() {
        return currentListings.map((pet) => {
            return (

                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    function currentListingsOrNot() {
        if (currentListings.length === 0) {
            return (
                <div className={viewUser.noListingsText}>
                    No Listings
                </div>
            );
        } else {
            return (
                <ul className={viewUser.listOfPets}>
                    {getCurrentListings()}
                </ul>
            );
        }
    }

    return (
        <div className={viewUser.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
            </style>
            {userData === "" || id === null ?
                <div className={viewUser.containerWithItems}>
                    <div className={viewUser.centerText}>
                        <div className={viewUser.signintext}>
                              This user does not exist.
                        </div>

                    </div>
                    <div className={viewUser.centerText}>
                        <Button
                            className={viewUser.editAccountButton}
                            sx={{
                                backgroundColor: '#89B0AE'
                            }}
                            variant="contained"
                            onClick={handleGoHome}>
                            Go Home
                        </Button>
                    </div>
                </div>
                :
                    <div>
                    <div className={viewUser.containerWithItems}>
                        <div className={viewUser.containersignin}>
                            <Stack direction="column">
                                <div className={viewUser.signintext}>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Avatar
                                            alt={userData.name}
                                            src={imagePreview}
                                            sx={{width: 100, height: 100, cursor: "pointer"}}
                                        />
                                        <div>{userData.name}</div>
                                        <div className={viewUser.messageUserButton}>
                                            <Button className={viewUser.editAccountButton}
                                                    sx={{
                                                        backgroundColor: '#89B0AE'
                                                    }}
                                                    variant="contained"
                                                    onClick={handleCurrentListingClick}
                                            >
                                                Message {userData.name}
                                            </Button>
                                        </div>
                                    </Stack>
                                </div>

                                <div className={viewUser.descriptionContainer}>
                                    <div className={viewUser.viewAccDetails}>
                                        {userData.description !== "" ? <div>{userData.description}</div> : ""}
                                    </div>
                                </div>
                            </Stack>
                        </div>
                    </div>
                    <div className={viewUser.center}>
                        <Stack direction="row" spacing={2}>
                            <div className={viewUser.petListingsContainer}>
                                <div className={viewUser.noListingsText}>
                                    <b>Listings:</b>
                                </div>
                                <div>
                                    {currentListingsOrNot()}
                                </div>
                            </div>
                        </Stack>
                    </div>
                </div>
            }
            </div>
    );
}
